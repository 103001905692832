export const state = {
  // Choices are provided by
  choices: {
    objectifCO2: {},
    specialities: {},
    workingAreas: {},
    legalStatuses: {},
  },
  // Options are computed from choices provided by the /meta endpoint
  options: {
    certificates: [],
    specialities: [],
    workingAreas: [],
    legalStatuses: [],
  },
  messages: [],
  stats: {},
  user: null,
};

function getOptionsFromChoices(choices) {
  const options = [];
  if (choices != null) {
    for (let [k, v] of Object.entries(choices)) {
      options.push({ value: k, text: v });
    }
  }
  return options;
}

export const mutations = {
  CHOICES_SET(state, payload) {
    state.choices.objectifCO2 = payload.choices.OBJECTIF_CO2_CHOICES;
    state.choices.legalStatuses = payload.choices.LEGAL_STATUS_CHOICES;
    state.choices.specialities = payload.choices.SPECIALITY_CHOICES;
    state.choices.workingAreas = payload.choices.WORKING_AREA_CHOICES;
  },
  MESSAGES_REMOVE(state) {
    state.messages.splice(0);
  },
  MESSAGE_ADD(state, payload) {
    state.messages.push(payload.message);
  },
  STATS_SET(state, payload) {
    if (payload.stats && payload.stats.carrier) {
      const carrierPayload = payload.stats.carrier;
      const carrierStats = {};
      // Format the payload data before assignment
      if (carrierPayload.date) {
        carrierStats.date = new Date(carrierPayload.date);
      }
      if (carrierPayload.count) {
        carrierStats.count = carrierPayload.count;
      }
      state.stats.carrier = carrierStats;
    }
  },
  OPTIONS_SET(state, payload) {
    // Sometimes, choices and options are required by the app or just one of them.
    state.options.certificates = getOptionsFromChoices(
      payload.choices.CERTIFICATE_CHOICES
    );
    state.options.legalStatuses = getOptionsFromChoices(
      payload.choices.LEGAL_STATUS_CHOICES
    );
    state.options.specialities = getOptionsFromChoices(
      payload.choices.SPECIALITY_CHOICES
    );
    state.options.workingAreas = getOptionsFromChoices(
      payload.choices.WORKING_AREA_CHOICES
    );
  },
  USER_SET(state, payload) {
    state.user = payload.user;
    state.user.carriers = payload.user.carriers;
  },
  USER_DELETE(state) {
    state.user = null;
  },
};
