<template lang="pug">
  div
</template>

<script>
export default {
  mounted() {
    this.$router.push({ name: "home" });
    this.$store.commit("MESSAGE_ADD", {
      message: "L'utilisateur a été déconnecté de A Dock et de FranceConnect",
    });
  },
};
</script>
