<template lang="pug">
v-form(@submit="submit")
  v-alert.adock-show-linebreaks(
    v-if="errorMessage"
    outlined
    text
  ) {{ errorMessage }}
  v-checkbox(
    v-if="!user.has_accepted_cgu"
    v-model="hasAcceptedCGU"
    @change="fieldErrors.has_accepted_cgu = null"
    :error-messages="fieldErrors.has_accepted_cgu"
    data-cy="inputHasAcceptedCGU"
    hide-details
  )
    template(v-slot:label="")
      span
        | Je certifie avoir lu et accepté les
        |
        router-link(:to="{name: 'cgu'}") Conditions générales d'utilisation
        | *
  v-checkbox(
    v-if="!user.has_accepted_cgu"
    v-model="isEntitled"
    label="Je certifie être habilité(e) à réaliser les démarches au nom de l'entreprise *"
    :error-messages="fieldErrors.has_accepted_cgu"
    data-cy="inputIsEntitled"
    hide-details
  )
  v-checkbox(
    v-model="hasSubscribedNewsletter"
    @change="fieldErrors.has_subscribed_newsletter = null"
    label="J'accepte d'être informé(e) des nouveautés et actualités d'A Dock"
    hide-details
  )
  p.pt-6.caption(v-if="!user.has_accepted_cgu") * champs obligatoires
  v-col
    v-row
      slot(
        v-bind:submit="submit"
        v-bind:isDisabled="isDisabled"
      )
        v-btn(
          @click="submit"
          :disabled="isDisabled"
          type="submit"
        ) Accepter
</template>

<script>
import api from "@/api";

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
    withNotificationMessages: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  data() {
    return {
      errorMessage: "",
      fieldErrors: {},
      hasAcceptedCGU: this.user.has_accepted_cgu,
      // Only used to disable the submit from UI
      isEntitled: this.user.has_accepted_cgu,
      hasSubscribedNewsletter: this.user.has_subscribed_newsletter,
    };
  },

  computed: {
    isDisabled() {
      if (!this.user.has_accepted_cgu) {
        return !this.hasAcceptedCGU || !this.isEntitled;
      }
      return false;
    },
  },

  methods: {
    async submit(e) {
      e.preventDefault();
      const response = await api.patch(api.profileUrl, {
        has_accepted_cgu: this.hasAcceptedCGU,
        has_subscribed_newsletter: this.hasSubscribedNewsletter,
      });
      if (response.status === 200) {
        const newUser = response.data.user;
        if (this.withNotificationMessages) {
          this.addNotificationMessages(newUser);
        }
        // Update user
        this.$store.commit("USER_SET", response.data);
        this.$emit("account-cgu-accepted", {
          account: {
            firstName: newUser.first_name,
            lastName: newUser.last_name,
            email: newUser.email,
          },
        });
      } else {
        if (response.data.errors) {
          this.fieldErrors = response.data.errors;
        }
        if (response.data.message) {
          this.errorMessage = response.data.message;
        }
      }
    },

    addNotificationMessages(newUser) {
      // It's impossible to disapprove CGU
      if (
        this.user.has_accepted_cgu === false &&
        newUser.has_accepted_cgu === true
      ) {
        this.$store.commit("MESSAGE_ADD", {
          message: "Vous avez approuvé les Conditions Générales d'Utilisation.",
        });
      }

      if (
        this.user.has_subscribed_newsletter !==
        newUser.has_subscribed_newsletter
      ) {
        let message;
        if (newUser.has_subscribed_newsletter) {
          message = "Vous êtes inscrit à la lettre d'information.";
        } else {
          message = "Vous n'êtes plus inscrit à la lettre d'information.";
        }
        this.$store.commit("MESSAGE_ADD", {
          message,
        });
      }
    },
  },
};
</script>
