<template lang="pug">
v-form(
  ref="carrierEditContainer"
  @submit="submit"
)
  v-alert.adock-show-linebreaks(
    v-if="errorMessage"
    type="error"
    outlined
    text
  ) {{ errorMessage }}
  v-row(dense)
    v-col
      h4 Contact
      p Facilitez les échanges avec vos clients en renseignant vos coordonnées commerciales essentielles.
      span.caption Les champs avec un * sont obligatoires.
  v-row(dense)
    v-col(cols="4" md="3")
      v-text-field(
        label="Téléphone *"
        v-model="form.phone"
        input="phone"
        :error-messages="fieldErrors.telephone"
        data-cy="carrierInputPhone"
        hint="Ce numéro de téléphone permet de contact rapidement votre entreprise."
      )
  v-row(dense)
    v-col(cols="8" md="5")
      v-text-field(
        label="Adresse e-mail commerciale *"
        v-model="form.email"
        input="email"
        :error-messages="fieldErrors.email"
        data-cy="carrierInputEmail"
        placeholder="contact@transporteur.fr"
        hint="Cette adresse est indispensable pour confirmer votre mise à jour et sécuriser votre fiche."
      )
  v-row(dense)
    v-col(cols="8" md="5")
      v-text-field(
        label="Site Web"
        v-model="form.website"
        input="url"
        :error-messages="fieldErrors.website"
        placeholder="https://www.transporteur.fr"
      )
  v-row(dense)
    v-col(md="10")
      h4.mt-2 Activité
      p Optimisez vos chances d’apparaître en tête des résultats de recherche en complétant le plus précisément possible les champs ci-dessous.
  v-row(dense)
    v-col(cols="8" md="5")
      v-select(
        label="Aire de travail *"
        v-model="form.workingArea"
        :items="options.workingAreas"
        :error-messages="fieldErrors.working_area"
        data-cy="carrierInputWorkingArea"
      )
  v-row(v-if="form.workingArea === 'REGION'" dense)
    v-col(cols="8" md="5")
      v-autocomplete(
        label="Votre région d'activité"
        v-model="form.region"
        @input="addDepartementsFromRegion"
        hint="Tous les départements des régions sélectionnées seront ajoutés aux départements livrés."
        :items="workingAreaRegions"
        :persistent-hint="true"
        data-cy="carrierInputWorkingAreaRegions"
      )
  v-row(v-if="['DEPARTEMENT', 'REGION'].includes(form.workingArea)" dense)
    v-col(cols="12")
      v-text-field(
        label="Départements livrés (numéros) *"
        v-model="workingAreaDepartementsInput"
        :error-messages="fieldErrors.working_area_departements"
        hint="Les numéros des départements doivent être séparés par des espaces ou des virgules."
        :rules="[workingAreaDepartementsRule]"
      )
  v-row(dense)
    v-col(cols="12")
      v-select(
        label="Spécialités *"
        v-model="form.specialities"
        :items="options.specialities"
        data-cy="carrierInputSpecialities"
        :error-messages="fieldErrors.specialities"
        chips
        multiple
        deletable-chips
      )
  v-row(dense)
    v-col(cols="12")
      v-textarea(
        label="Description de l'activité"
        v-model="form.description"
        :rows="3"
        :error-messages="fieldErrors.description"
        placeholder="Personnalisez votre fiche entreprise avec une brève description de votre activité."
      )
  v-row(dense)
    v-col(cols="12" align="right")
      slot(name="cancel-button")
        v-btn.mr-4(
          v-if="cancelUrl"
          :to="cancelUrl"
        ) Annuler
      slot(
        name="submit-button"
        v-bind:submit="submit"
      )
        v-btn(
          color="primary"
          type="submit"
        ) Modifier
</template>

<style lang="scss">
.flex.flex-bottom {
  flex-basis: 0;
}
</style>

<script>
import { mapState } from "vuex";

import CarrierCardHeader from "@/components/CarrierCardHeader";

import api from "@/api";
import { scrollToErrorsMixin, routeLoadCarrierMixin } from "@/mixins";

function sortUniq(a) {
  return a.sort().filter((item, pos, array) => !pos || item !== array[pos - 1]);
}

function joinDepartements(departements) {
  return departements.join(", ");
}

function splitDepartements(departementsString) {
  const departements = [];
  const commaDepartements = departementsString.split(",");
  commaDepartements.forEach((commaDepartement) => {
    const departementsTmp = commaDepartement.split(" ");
    departements.push(
      ...departementsTmp.filter((departement) => departement !== "")
    );
  });
  return departements;
}

const WORKING_AREA_REGIONS = {
  "Auvergne-Rhône-Alpes": [
    "01",
    "03",
    "07",
    "15",
    "26",
    "38",
    "42",
    "43",
    "63",
    "69",
    "73",
    "74",
  ],
  "Bourgogne-France-Comté": ["21", "25", "39", "58", "70", "71", "89", "90"],
  Bretagne: ["22", "29", "35", "56"],
  "Centre-Val de Loire": ["18", "28", "36", "37", "41", "45"],
  Corse: ["2A", "2B"],
  "Grand Est": ["08", "10", "51", "52", "54", "55", "57", "67", "68", "88"],
  "Hauts-de-France": ["02", "59", "60", "62", "80"],
  "Île-de-France": ["75", "77", "78", "91", "92", "93", "94", "95"],
  Normandie: ["14", "27", "50", "61", "76"],
  "Nouvelle-Aquitaine": [
    "16",
    "17",
    "19",
    "23",
    "24",
    "33",
    "40",
    "47",
    "64",
    "79",
    "86",
    "87",
  ],
  Occitanie: [
    "09",
    "11",
    "12",
    "30",
    "31",
    "32",
    "34",
    "46",
    "48",
    "65",
    "66",
    "81",
    "82",
  ],
  "Pays de la Loire": ["44", "49", "53", "72", "85"],
  "Provence-Alpes-Côte d'Azur": ["04", "05", "06", "13", "83", "84"],
  Guadeloupe: ["971"],
  Martinique: ["972"],
  Guyane: ["973"],
  "La Réunion": ["974"],
  Mayotte: ["976"],
};

export default {
  mixins: [scrollToErrorsMixin, routeLoadCarrierMixin],

  props: {
    carrier: {
      type: Object,
      required: true,
    },
    cancelUrl: {
      type: Object,
      required: false,
      default: null,
    },
    extendPayload: {
      type: Function,
      required: false,
      default: null,
    },
  },

  components: {
    CarrierCardHeader,
  },

  data() {
    const counties = this.carrier.working_area_departements;
    return {
      form: {
        email: this.carrier.email || "",
        phone: this.carrier.telephone || "",
        workingArea: this.carrier.working_area || "",
        workingAreaDepartements: counties != null ? counties : [],
        specialities: this.carrier.specialities || [],
        website: this.carrier.website || "",
        description: this.carrier.description || "",
      },
    };
  },

  computed: {
    workingAreaRegions() {
      // Returns the list of regions names
      return Object.keys(WORKING_AREA_REGIONS);
    },
    workingAreaDepartementsInput: {
      get: function () {
        return joinDepartements(this.form.workingAreaDepartements);
      },
      set: function (newValue) {
        this.form.workingAreaDepartements = splitDepartements(newValue);
      },
    },
    ...mapState(["options"]),
  },

  methods: {
    addDepartementsFromRegion() {
      if (this.form.region in WORKING_AREA_REGIONS) {
        this.form.workingAreaDepartements.push(
          ...WORKING_AREA_REGIONS[this.form.region]
        );
      }
      this.form.workingAreaDepartements = sortUniq(
        this.form.workingAreaDepartements
      );
      this.form.region = "";
    },

    getPayloadFromForm() {
      return {
        email: this.form.email,
        telephone: this.form.phone,
        working_area: this.form.workingArea,
        working_area_departements: this.form.workingAreaDepartements,
        specialities: this.form.specialities,
        website: this.form.website,
        description: this.form.description,
      };
    },

    async submit(e) {
      e.preventDefault();
      const payload = this.getPayloadFromForm();
      // Used to provide created_by in stepper of subscription
      if (this.extendPayload !== null) {
        this.extendPayload(payload);
      }

      const response = await api.post(
        api.getCarrierUrl(this.carrier.siret),
        payload
      );
      if (response.status === 200) {
        this.$emit("carrier-saved", response.data);
      } else {
        this.setErrorsAndScrollTo(
          response.data,
          this.$refs.carrierEditContainer
        );
      }
    },

    workingAreaDepartementsRule(value) {
      if (["DEPARTEMENT", "REGION"].includes(this.form.workingArea) && !value) {
        return "Des départements doivent être renseignés quand l'aire de travail est départementale ou régionale.";
      } else {
        return true;
      }
    },
  },
};
</script>
