import axios from "axios";
import * as Sentry from "@sentry/vue";

import auth from "@/auth";
import router from "@/router";

export const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: process.env.VUE_APP_AXIOS_TIMEOUT,
});

// Add JWT token to requests if available
axiosInstance.interceptors.request.use((config) => {
  const token = auth.getToken();
  if (token) {
    config.headers["Authorization"] = auth.getTokenType() + " " + token;
  }
  return config;
});

export const api = {
  // Server URLs
  franceConnectCallbackUrl: "/accounts/fc/callback",
  franceConnectLogoutUrl: "/accounts/fc/logout",
  loginCreateUrl: "/accounts/",
  loginUrl: "/accounts/login",
  metaUrl: "/meta/",
  accountRecoverUrl: "/accounts/recover",
  accountMailConfirmationLinkUrl: "/accounts/mailconfirmationlink",
  passwordResetUrl: "/accounts/password/reset",
  profileUrl: "/accounts/profile",
  searchCarriersUrl: "/carriers/search",
  searchCarriersCanEditUrl: "/carriers/search/canedit",
  searchSireneUrl: "/sirene/search",
  selfTestsUrl: "/selftests/",
  statsUrl: "/stats/",
  threadsUrl: "/threads/",
  threadsForManagersUrl: "/threads/managers",

  getAccountConfirmUrl(
    userId,
    userToken,
    carrierEditableId,
    carrierEditableToken
  ) {
    if (carrierEditableId && carrierEditableToken) {
      return `/accounts/${userId}/confirm/${userToken}/carrier/editable/${carrierEditableId}/confirm/${carrierEditableToken}`;
    }
    return `/accounts/${userId}/confirm/${userToken}`;
  },

  getCarrierCertificateUrl(carrierSiret) {
    return `/carriers/${carrierSiret}/certificate`;
  },

  getCarrierUrl(carrierSiret) {
    return `/carriers/${carrierSiret}`;
  },

  getCarrierManagementUrl(carrierSiret) {
    return `/carriers/${carrierSiret}/management`;
  },

  getCarrierEditableConfirmUrl(carrierEditableId, token) {
    return `/carriers/editable/${carrierEditableId}/confirm/${token}`;
  },

  getThreadUrl(threadId) {
    return `/threads/${threadId}`;
  },

  handleCommunicationError(axiosError) {
    let message = null;
    // Returns an object with 'status' (integer), 'data.message' (text) and all the
    // attributes of 400 responses.
    if (axiosError.response == null) {
      Sentry.captureException(axiosError);
      message = `Le serveur ${process.env.VUE_APP_API_URL} est inaccessible ou erreur d'exécution Javascript.`;
      router.push({
        name: "error",
        status: 503,
        message,
      });
      return {
        status: 503,
        data: {
          message,
        },
      };
    }

    const response = axiosError.response;
    if (response.status >= 500) {
      // Django will certainly also call Sentry
      Sentry.captureException(axiosError);
      message =
        `Le service a retourné une erreur. Les administrateurs ont été informés du problème.` +
        ` Nous vous invitons à reprendre votre démarche ultérieurement.`;
      router.push({
        name: "error",
        params: {
          message,
          status: response.status,
        },
      });
    } else if (response.status === 404) {
      message = "La ressource demandée n'existe pas";
      if (axiosError.request && axiosError.request.responseURL) {
        message += ` « ${axiosError.request.responseURL} »`;
      }
      message += ".";
      router.push({
        name: "error",
        params: {
          message,
          status: response.status,
        },
      });
    } else if (response.status === 401) {
      // No token or expired
      router.push({ name: "accounts__login" });
    }
    if (message) {
      response.data = Object.assign({}, response.data, { message });
    }

    return response;
  },

  /* The data structure contains:
     - a field with the instance or the list of instances
     - a field 'errors' which can be a string or an object with an entry for each field (form)
  */
  async get(url, params, { withErrorHandling = true } = {}) {
    try {
      return await axiosInstance.get(url, { params });
    } catch (axiosError) {
      if (withErrorHandling) {
        return this.handleCommunicationError(axiosError);
      } else {
        return axiosError.response;
      }
    }
  },

  async method(method, url, data) {
    try {
      return await axiosInstance({
        method,
        url,
        data,
      });
    } catch (axiosError) {
      return this.handleCommunicationError(axiosError);
    }
  },

  delete(url) {
    return this.method("delete", url);
  },

  patch(url, data) {
    return this.method("patch", url, data);
  },

  post(url, data) {
    return this.method("post", url, data);
  },
};

export default api;
