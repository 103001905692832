<template lang="pug">
div
  div.adock-section.adock-section-grey
    v-container.pt-0
      v-stepper.adock-card-with-header(
        v-model="step"
        alt-labels
      )
        v-stepper-header
          v-stepper-step.text-center(
            step="1"
            editable
            :complete="step > 1"
          ) Recherchez votre entreprise
          v-divider
          v-stepper-step.text-center(
            step="2"
            :complete="step > 2"
          ) Complétez votre fiche entreprise
        v-stepper-items
          v-stepper-content(
            step="1"
          )
            v-row
              v-col(cols="12" offset-md="1" md="10")
                v-container
                  p.title.hidden-md-and-up 1. Recherchez votre entreprise
                  CarrierSearch(
                    :key="carrierSearchResetKey"
                    :canEdit="true"
                    @carrier-selected="getSelectedCarrier($event)"
                  )
            v-row.mt-2
              v-col(offset="1" cols="10" align="right")
                v-btn(
                  color="primary"
                  :disabled="!carrier || !carrier.user_can_edit"
                  @click="nextStepFromCarrierSelected"
                ) Suivant
          v-stepper-content(
            step="2"
          )
            v-row
              v-col(cols="12" offset-md="1" md="10")
                v-container
                  p.title.hidden-md-and-up 2. Complétez votre fiche entreprise
                  CarrierEditForm(
                    v-if="carrier"
                    :carrier="carrier"
                    @carrier-saved="nextStepFromCarrierSaved($event)"
                  )
                    template(v-slot:submit-button="slotProps")
                      v-btn(
                        color="primary"
                        @click.native="slotProps.submit"
                      ) Enregister
</template>

<script>
import * as Sentry from "@sentry/vue";
import { URL_TRANSPORTEURS__AJOUT } from "@/router";

import api from "@/api";
import CarrierEditForm from "@/components/CarrierEditForm.vue";
import CarrierSearch from "@/components/CarrierSearch.vue";

export default {
  components: {
    CarrierEditForm,
    CarrierSearch,
  },

  data() {
    return {
      carrierSearchResetKey: 0,
      step: 1,
      carrier: null,
    };
  },

  watch: {
    carrier: function (newValue) {
      if (newValue === null) {
        // Reset subcomponent on carrier reset
        this.carrierSearchResetKey += 1;
      }
    },
  },

  methods: {
    getSelectedCarrier(data) {
      this.carrier = data;
    },

    async nextStepFromCarrierSelected() {
      const url = api.getCarrierUrl(this.carrier.siret);
      const response = await api.get(url);
      if (response.status === 200) {
        this.carrier = response.data.carrier;
        this.step = 2;
      } else {
        this.$router.push({
          name: "error",
          params: {
            message: response.data.message,
            status: response.status,
            fallbackUrl: URL_TRANSPORTEURS__AJOUT,
          },
        });
      }
    },

    async nextStepFromCarrierSaved(data) {
      if (data.confirmation) {
        this.$router.push({
          name: "confirmations__waiting",
          params: {
            message: data.confirmation.message,
          },
        });
      } else if (data.message) {
        this.$store.commit("MESSAGE_ADD", data);
        this.$router.push({ name: "accounts__dashboard" });
      } else {
        // We're lost...
        Sentry.captureMessage("Don't know where to go...");
        this.router.push("/");
      }
    },
  },
};
</script>
