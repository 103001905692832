<template lang="pug">
  v-container.fill-height
    v-row(
      align="center"
      justify="center"
    )
      v-col(cols="12" sm="8" md="6")
        v-card.adock-card-with-header
          v-toolbar(
            color="primary"
            dark
            flat
          )
            v-toolbar-title
              h4 Connectez-vous
          v-container.pa-6
            v-row
              v-col.text-center
                v-btn.adock-france-connect(
                  @click="goToFranceConnectAuthorizeUrl"
                )
                p
                  a(
                    href="https://app.franceconnect.gouv.fr/en-savoir-plus"
                  ) Qu’est-ce que FranceConnect ?
                p.adock-grey-text ou
            v-form(
              v-model="isValid"
              ref="form"
              @submit="submit"
            )
              v-alert.adock-show-linebreaks(
                v-if="errorMessage"
                type="error"
                outlined
                text
              ) {{ errorMessage }}
              v-text-field(
                id="email"
                label="Adresse e-mail"
                type="email"
                v-model="email"
                :rules="emailRules"
                validate-on-blur
                :error-messages="fieldErrors.email"
                data-cy="inputEmail"
              )
              v-text-field(
                id="password"
                label="Mot de passe"
                type="password"
                v-model="password"
                :rules="passwordRules"
                :append-icon="isPlainPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="() => (isPlainPassword = !isPlainPassword)"
                :type="isPlainPassword ? 'text': 'password'"
                counter
                validate-on-blur
                :error-messages="fieldErrors.password"
                data-cy="inputPassword"
              )
            v-row
              v-col
                router-link(
                  :to="{name: 'accounts__recover', params: {email: this.email}}"
                ) Je n'ai plus accès à mon compte
            v-row
              v-col(cols="7")
                router-link(:to="{name: 'carriers__subscribe'}") S'inscrire au service A Dock
              v-col(cols="5" align="right")
                v-btn(
                  type="submit"
                  color="primary"
                  :disabled="isDisabled"
                  @click="submit"
                ) Se connecter
</template>

<style lang="scss">
.v-btn.adock-france-connect {
  display: inline-block;
  height: 52px !important;
  width: 186px;
  margin: auto;
  margin-bottom: 8px;
  background-image: url("../assets/france-connect-white.svg");
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
}

.adock-france-connect:hover {
  background-image: url("../assets/france-connect-hover.svg");
}
</style>

<script>
/* To avoid to display fields in error when the user types they are validated on
  blur event but it's nice to change the state of submit button before the blur
  event. To provide that feature, the component provides a computed value
  dedicated to the submit button.
*/
import api from "@/api";
import auth from "@/auth";
import { emailRules, passwordRules } from "@/mixins";
import {
  getRouterLocationWhenLogged,
  getNextUrlFromRoute,
  getFranceConnectAuthorizeUrl,
} from "@/router";

export default {
  data() {
    return {
      email: "",
      isValid: false,
      isPlainPassword: false,
      password: "",
      errorMessage: "",
      fieldErrors: {},
    };
  },

  created() {
    this.emailRules = emailRules;
    this.passwordRules = passwordRules;
  },

  computed: {
    isDisabled() {
      // Only for submit button
      const statuses = [];
      this.emailRules.forEach((rule) => statuses.push(rule(this.email)));
      this.passwordRules.forEach((rule) => statuses.push(rule(this.password)));
      return !statuses.every((v) => v === true);
    },
  },

  methods: {
    goToFranceConnectAuthorizeUrl() {
      window.location.assign(getFranceConnectAuthorizeUrl(this.$route));
    },
    async submit(e) {
      e.preventDefault();
      if (this.$refs.form.validate()) {
        this.errorMessage = "";
        this.fieldErrors = {};

        auth.deleteTokenData();
        const response = await api.post(api.loginUrl, {
          username: this.email,
          password: this.password,
        });
        if (response.status === 200) {
          auth.setTokenData(response.data);
          await this.$store.dispatch("loadUserProfile");
          const routerLocation = getRouterLocationWhenLogged(
            this.$store.state.user,
            getNextUrlFromRoute(this.$route)
          );
          if (!routerLocation) {
            this.errorMessage =
              "Succès de l'identification mais échec des contrôles de sécurité.";
          } else {
            // Logged
            this.$router.push(routerLocation);
          }
        } else {
          if (response.data.errors) {
            if (response.data.errors.fields) {
              this.fieldErrors = response.data.errors.fields;
            }
            if (response.data.errors.__all__) {
              // Change the original message
              this.errorMessage =
                "L'adresse e-mail et le mot de passe ne correspondent pas.";
            }
          } else {
            this.errorMessage = response.data.message;
          }
        }
      }
    },
  },
};
</script>
