<template lang="pug">
  v-card.adock-card.mt-2
    v-card-title(primary-title)
      h2 Messages
    ThreadList(
      :threads-url="threadsUrl"
      :get-router-thread-url="getRouterThreadUrl"
      :show-id="true"
    )
</template>

<script>
import api from "@/api";
import ThreadList from "@/components/ThreadList.vue";

export default {
  components: {
    ThreadList,
  },

  data() {
    return {
      threadsUrl: api.threadsForManagersUrl,
      getRouterThreadUrl: (threadId) => {
        return { name: "managers__thread", params: { threadId } };
      },
    };
  },
};
</script>
