import api from "@/api";

export async function routeGet(routeTo, routeFrom, onSuccess, url) {
  // Errors are handled by get()
  const response = await api.get(url);
  if (response.status === 200) {
    // Success
    return onSuccess(response.data);
  }
}
