<template lang="pug">
div.fill-height.pt-0.adock-section.adock-section-grey
  v-container(fluid)
    v-row
      v-col.pl-0.pt-0.hidden-print-only(cols="3" lg="2")
        v-navigation-drawer(permanent)
          v-list-item
            v-list-item-content
              v-list-item-title.title Instruction
          v-divider
          v-list.adock-list-nav(
            nav
            v-if="user"
          )
            v-list-item(
              link
              :to="{name: 'managers__threads'}"
            )
              v-list-item-content
                v-list-item-title Messages
      v-col(cols="9" lg="10")
        router-view
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["user"]),
  },
};
</script>
