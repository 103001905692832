import Vue from "vue";

Vue.filter("asJoinedString", (value) => {
  return value ? value.join(", ") : "";
});

Vue.filter("asLocaleDate", (value) => {
  if (value == null) {
    return "";
  }
  return new Date(value).toLocaleDateString();
});

Vue.filter("asLocaleDateTime", (value) => {
  if (value == null) {
    return "";
  }
  const d = new Date(value);
  return d.toLocaleDateString() + " " + d.toLocaleTimeString();
});

Vue.filter("asSiren", (value) => {
  return value.slice(0, 9);
});
