import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-height pt-0 adock-section adock-section-grey"},[_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{staticClass:"pl-0 pt-0",attrs:{"cols":"3","lg":"2"}},[_c(VNavigationDrawer,{attrs:{"permanent":""}},[_c(VListItem,[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"title"},[_vm._v("Mon espace")])],1)],1),_c(VDivider),(_vm.user)?_c(VList,{staticClass:"adock-list-nav",attrs:{"nav":""}},[_c(VListItem,{attrs:{"link":"","to":{name: 'accounts__dashboard'},"exact":"","disabled":!_vm.user.is_confirmed}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Vos entreprises")])],1)],1),_c(VListItem,{attrs:{"link":"","to":{name: 'accounts__profile'}}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Paramètres")])],1)],1),_c(VListItem,{attrs:{"link":"","to":{name: 'accounts__threads'},"disabled":!_vm.user.is_confirmed}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Messagerie")])],1)],1)],1):_vm._e()],1)],1),_c(VCol,{attrs:{"cols":"9","lg":"10"}},[_c('router-view')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }