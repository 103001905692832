<template lang="pug">
div.adock-section.adock-section-grey
  h3.text-center Ce que nos utilisateurs pensent du service
  v-container(
    d-flex
    px-0
  )
    v-row
      v-col(
        v-for="testimonial in testimonials"
        :key="testimonial.text"
        cols="10" offset="1" md="4" offset-md="0"
        dense
      )
        v-card(height="100%")
          v-col(cols="10" offset="1")
            blockquote.blockquote
              v-icon mdi-format-quote-open
              | {{ testimonial.quote }}
              br
              br
              h4.text-sm-left {{ testimonial.author }}
</template>

<script>
export default {
  data() {
    return {
      testimonials: [
        {
          author: "STEP",
          quote: `En tant que petite société de transport naissante, cet outil peut être très
            utile pour se faire connaître.`,
        },
        {
          author: "Les Routiers Bretons",
          quote: `Facile à utiliser et saisie rapide à faire.`,
        },
        {
          author: "Interloc TP",
          quote: `C'est vraiment encourageant pour la profession de voir que l'État
            met en place des aides au développement de notre activité.`,
        },
      ],
    };
  },
};
</script>
