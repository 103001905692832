import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemActionText } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.searchResponseIsEmpty)?_c(VCard,{staticClass:"adock-card mt-1"},[_c(VCardText,[_vm._v("La recherche avec "+_vm._s(_vm.searchParamsForDisplay)+" n'a retourné aucun résultat.")])],1):_vm._e(),(_vm.carriers && _vm.carriers.length > 0)?_c(VCard,{staticClass:"adock-card mt-1"},[_c(VList,{attrs:{"two-line":""}},[(_vm.limit)?_c(VSubheader,[_vm._v("Seules les "+_vm._s(_vm.carriers.length)+" premières entreprises de la recherche "+_vm._s(_vm.searchParamsForDisplay)+" sont affichées.")]):_c(VSubheader,[_vm._v(_vm._s(_vm.carriers.length)+" entreprise"+_vm._s(_vm.carriers.length > 1 ? "s" : "")+" pour la recherche "+_vm._s(_vm.searchParamsForDisplay))]),_vm._l((_vm.carriers),function(carrier,index){return [(index !== 0)?_c(VDivider,{key:'d-' + carrier.siret}):_vm._e(),_c(VListItem,{key:carrier.siret,attrs:{"to":{ name: 'carriers__detail', params: { carrierSiret: carrier.siret, fromSearch: true }}}},[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"adock-carrier-list-item"},[_vm._v(_vm._s(carrier.enseigne))]),_c(VListItemSubtitle,{staticClass:"adock-carrier-list-item"},[_vm._v(_vm._s(carrier.code_postal)+" "+_vm._s(carrier.ville))])],1),_c(VListItemAction,[_c(VListItemActionText,{staticClass:"adock-carrier-list-details"},[_c('span',[_vm._v(" < 3,5 tonnes : "+_vm._s(carrier.lti_nombre))]),_c('br'),_c('span',[_vm._v("> 3,5 tonnes : "+_vm._s(carrier.lc_nombre))]),_c('br'),_c('span',[_vm._v(_vm._s(_vm.choices.workingAreas[carrier.working_area]))])])],1)],1)]})],2)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }