import Vue from "vue";
import Vuetify from "vuetify/lib";
import fr from "vuetify/es5/locale/fr";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#0053B3",
        secondary: "#EBEFF3",
        accent: "#006BE6",
      },
    },
  },
  lang: {
    locales: { fr },
    current: "fr",
  },
  icons: {
    iconfont: "mdi",
  },
});
