<template lang="pug">
  div
    v-card.adock-card.mt-2(v-if="searchResponseIsEmpty")
      v-card-text La recherche avec {{ searchParamsForDisplay }} n'a retourné aucun résultat.
    v-card.adock-card.mt-2(v-if="carriers && carriers.length > 0")
      v-row
        v-col(cols="12" md="6")
          span Sélectionnez votre entreprise :
          v-list(
            subheader
            three-line
          )
            v-radio-group.mt-0(
              v-model="selectedCarrier"
            )
              template(
                v-for="(carrier, index) in carriers"
              )
                v-divider(
                  v-if="index !== 0"
                  :key="'d-' + carrier.siret"
                )
                v-list-item(
                  :key="carrier.siret"
                  ripple
                  @click="selectedCarrier = carrier"
                  :class="{'grey lighten-4': selectedCarrier === carrier }"
                )
                  v-list-item-action
                    v-radio(
                      :value="carrier"
                    )
                  v-list-item-content
                    v-list-item-title.adock-carrier-list-item {{ carrier.enseigne }}
                    v-list-item-subtitle.adock-carrier-list-item
                      | {{ carrier.code_postal }} {{ carrier.ville }}
                    v-list-item-subtitle.adock-carrier-list-item
                      | SIREN {{ carrier.siret | asSiren }}
            span.adock-grey-text.caption(v-if="limit")
              | Seules les {{ carriers.length }} premières entreprises de la
              | recherche {{ searchParamsForDisplay }} sont affichées.
              | Si votre entreprise n'est pas listée, renseignez directement son numéro SIREN.
            span.adock-grey-text.caption(v-else) La recherche {{ searchParamsForDisplay }} a retourné {{ carriers.length }} entreprise{{ carriers.length > 1 ? "s" : "" }}.
        v-col(cols="12" md="6")
          v-container.pt-2(
            fill-height
          )
            v-row(
              v-if="selectedCarrier"
              align="center"
            )
              v-col.text-center
                p Entreprise sélectionnée :
                h2 {{ selectedCarrier.enseigne }}
                span {{ selectedCarrier.adresse }}
                br
                span {{ selectedCarrier.code_postal }} {{ selectedCarrier.ville }}
                br
                span SIREN {{ selectedCarrier.siret | asSiren }}
                br
                p.orange--text(v-if="selectedCarrier.user_can_edit === false")
                  | Vous ne pouvez pas vous associer avec cette entreprise car elle dispose déjà
                  | d'un utilisateur administrateur.
                  | Contactez nous à contact@adock.beta.gouv.fr pour plus d'informations
            v-row(
              v-else
              align="center"
            )
              v-col.text-center
                span Aucune entreprise sélectionnée
</template>

<script>
export default {
  props: {
    searchResponseIsEmpty: {
      type: Boolean,
      required: true,
    },
    searchParams: {
      type: Object,
      default: null,
    },
    // Array or null
    carriers: {
      required: true,
    },
    limit: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      selectedCarrier: null,
    };
  },

  computed: {
    searchParamsForDisplay() {
      const messages = [];

      if (this.searchParams === null) {
        return "";
      }

      if (this.searchParams.q !== "") {
        messages.push(`« ${this.searchParams.q} »`);
      }

      return messages.join(", ");
    },
  },

  watch: {
    carriers: function (newValue) {
      if (newValue === null) {
        // Remove selection
        this.selectedCarrier = null;
      }
    },

    selectedCarrier: function () {
      this.$emit("carrier-selected", this.selectedCarrier);
    },
  },
};
</script>
