<template lang="pug">
div
  CarrierHeader
  div.adock-section.adock-section-white
    v-container(justify="center")
      v-row(
        align="center"
      )
        v-col(offset="1" cols="10" md="7")
          h2
            | Connectez vous à votre espace, point d'accès à votre fiche entreprise
            | et vos démarches administratives en ligne.
        v-col(cols="12" md="2")
          v-row
            v-col
              v-btn(
                color="primary"
                :to="{name: 'carriers__subscribe'}"
                x-large
                width="10em"
              ) S'inscrire
          v-row
            v-col
              v-btn(
                :to="{name: 'accounts__login'}"
                x-large
                width="10em"
              ) Se connecter
  div.adock-section.adock-section-grey
    v-row(justify="space-around")
      v-col(cols="12" md="3")
        p.text-center
          v-icon.adock-icon-black(x-large) mdi-eye-check-outline
        h3.text-center Votre visibilité en ligne
        p.text-center
          | Découvrez la fiche entreprise qui présente votre activité et
          | facilite vos échanges avec vos partenaires commerciaux.
      v-col(cols="12" md="3")
        p.text-center
          v-icon.adock-icon-black(x-large) mdi-text-box-check-outline
        h3.text-center Vos démarches en ligne
        p.text-center
          | Découvrez dans votre espace les démarches disponibles pour votre entreprise :
          | demandes administratives DREAL, attestations à générer.
  div.adock-section.adock-section-dark-blue.white--text
    h3.text-center Les démarches administratives simplifiées sur adock.beta.gouv.fr
    p.text-center Dématérialisation en expérimentation avec la DREAL Bretagne
    v-row.mt-5(justify="space-around")
      v-col(cols="12" md="3")
        h3.text-center Renouvellement de licences
      v-col(cols="12" md="3")
        h3.text-center Demande de copies supplémentaires
      v-col(cols="12" md="3")
        h3.text-center Demande d’autorisation d’accès à la profession de transporteur routier
  div.adock-section
    h3.text-center La visibilité renforcée de votre entreprise sur adock.beta.gouv.fr
    v-row.pt-6(justify="space-around")
      v-col.text-center(cols="12" md="3")
        h2
          router-link.adock-no-link(:to="{name: 'stats'}") 1
        h3 fiche entreprise
        p À compléter pour une relation transporteur-chargeur directe, facilitée et sécurisée par l’État.
      v-col.text-center(cols="12" md="3")
        h2
          router-link.adock-no-link(:to="{name: 'stats'}") 30
        h3 données gratuitement visibles
        p Contact, spécialités, aires de travail, licences, Objectif CO
          sub 2
          | …
      v-col.text-center(cols="12" md="3")
        h2
          router-link.adock-no-link(:to="{name: 'stats'}") 2
        h3 attestations à générer et télécharger
        p Attestations d’emploi ou de non emploi de travailleurs soumis à autorisation de travail.
  CarrierTestimonialCards
  Contact
</template>

<script>
import CarrierHeader from "@/components/CarrierHeader.vue";
import CarrierTestimonialCards from "@/components/CarrierTestimonialCards.vue";
import Contact from "@/components/Contact.vue";

export default {
  components: {
    CarrierHeader,
    CarrierTestimonialCards,
    Contact,
  },
};
</script>
