<template lang="pug">
  v-card.mt-2.adock-card
    v-card-title(primary-title)
      h2 Vos entreprises de transport
    v-card-text.pb-0
      | Visualisez les fiches de vos entreprises, modifiez leurs informations de contact
      | et d’activité, effectuer vos démarches administratives en cliquant sur Modifier et effectuer vos démarches.
    v-container.px-12(v-if="user && user.carriers && user.carriers.length > 0")
      v-row
        v-col(
          cols="12" md="6"
          v-for="carrier in user.carriers"
          :key="carrier.siret"
        )
          v-card.pa-2(
            outlined
          )
            v-list-item(two-line)
              v-list-item-content
                v-list-item-title.headline.mb-1 {{ carrier.enseigne }}
                v-list-item-subtitle
                  | {{ carrier.code_postal }} {{ carrier.ville }}
                  br
                  | SIREN {{ carrier.siret | asSiren }}
            v-card-text(
              v-if="carrier.license_almost_expired"
            )
              v-alert(
                dense
                type="warning"
                outlined
                text
              )
                | Vos licences arrivent à expiration le {{ getLicenseFirstExpirationDate(carrier) | asLocaleDate }}.
            v-card-text(
              v-if="carrier.unconfirmed_changes_count > 0"
            )
              v-alert(
                dense
                type="warning"
                outlined
                text
              )
                | {{ carrier.unconfirmed_changes_count }} confirmation
                span(v-if="carrier.unconfirmed_changes_count > 1") s
                |
                | en attente
            v-card-actions
              v-btn.mb-2(
                color="primary"
                :to="{name: 'accounts__carrier', params:{ carrierSiret: carrier.siret }}"
              ) Modifier et effectuer vos démarches
    v-card-text.text--primary(v-else) Aucune entreprise associée à ce compte.
    v-card-actions
      v-col
        v-btn(
          :to="{name: 'carriers__add'}"
        ) Ajouter une nouvelle entreprise
</template>

<script>
import { mapState } from "vuex";

export default {
  mounted() {
    this.$store.dispatch("loadUserProfile");
  },

  computed: {
    ...mapState(["choices", "user"]),
  },

  methods: {
    getLicenseFirstExpirationDate: function (carrier) {
      if (carrier.lti_date_fin === null) {
        return new Date(carrier.lc_date_fin);
      }

      if (carrier.lc_date_fin === null) {
        return new Date(carrier.lti_date_fin);
      }

      // We compare ISO strings
      if (carrier.lti_date_fin < carrier.lc_date_fin) {
        return new Date(carrier.lti_date_fin);
      } else {
        return new Date(carrier.lc_date_fin);
      }
    },
  },
};
</script>
