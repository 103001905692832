<template lang="pug">
  v-data-table(
    :headers="headers"
    :items="threads"
    sort-by="data.created_at"
    :items-per-page=15
    @click:row="onRowClick"
  )
    template(v-slot:item.created_at="{ item }") {{ item.created_at | asLocaleDate }}
    template(v-slot:item.actions="{ item }")
      v-btn.ma-1(
        :to="getRouterThreadUrl(item.id)"
        depressed
      )
        v-icon(
          small
          class="mr-2"
        ) mdi-eye
        | Lire
</template>

<script>
import api from "@/api";

export default {
  props: {
    threadsUrl: {
      required: true,
      type: String,
    },
    getRouterThreadUrl: {
      required: true,
      type: Function,
    },
    showId: {
      required: false,
      default: false,
      type: Boolean,
    },
  },

  data() {
    let headers = [];

    if (this.showId) {
      headers.push({
        text: "ID",
        value: "id",
      });
    }

    headers.push(
      {
        text: "Date",
        value: "created_at",
      },
      {
        text: "Sujet",
        value: "title",
      },
      {
        text: "Statut",
        value: "state_display",
      },
      {
        text: "De",
        value: "from_user.full_name",
      },
      {
        text: "À",
        value: "to_user.full_name",
      },
      {
        text: "",
        value: "actions",
      }
    );

    return {
      headers,
      errorMessage: null,
      threads: [],
    };
  },

  created() {
    this.setup();
  },

  methods: {
    async setup() {
      const response = await api.get(this.threadsUrl);
      if (response.status === 200) {
        this.threads = response.data.threads;
        this.errorMessage = null;
      } else {
        this.errorMessage = null;
        if (response.data.message) {
          this.errorMessage = response.data.message;
        }
        this.threads = [];
      }
    },

    onRowClick(thread) {
      this.$router.push(this.getRouterThreadUrl(thread.id));
    },
  },
};
</script>
