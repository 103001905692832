import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"adock-card mt-2"},[_c(VCardTitle,{attrs:{"primary-title":""}},[_c('h2',[_vm._v("Vos messages")])]),_c(VCardText,{staticClass:"pb-0"},[_vm._v("Contactez les administrateurs du site.")]),_c(VRow,[_c(VCol,{attrs:{"align":"right"}},[_c(VBtn,{attrs:{"color":"primary","to":{'name': 'accounts__thread_new'}}},[_vm._v("Nouveau message")])],1)],1),_c('ThreadList',{attrs:{"threads-url":_vm.threadsUrl,"get-router-thread-url":_vm.getRouterThreadUrl}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }