<template lang="pug">
v-img.white--text(:src="roadPicture" height="200px")
  v-container(fill-height)
    v-row.mt-4.mx-2(align="end")
      v-col
        v-row(no-gutters)
          v-col(cols="10")
            v-row(align="start" justify="start" no-gutters)
              v-col
                h2 {{ carrier.enseigne }}
                span.white--text.text--darken-1 {{ carrier.libelle_ape }}
                br
                span.white--text.text--darken-1(v-if="isSubsidiary")
                  | Filiale de
                  |
                  router-link.white--text(:to="{name: 'carriers__detail', params: {carrierSiret: this.headquarters.siret }}") {{ headquarters.enseigne }}
                span.white--text.text--darken-1(v-else)
                  v-icon.mr-1(dark) mdi-domain
                  | Siège de l'entreprise
                  span(v-if="carrier.other_facilities.length > 0")
                    |  -
                    |
                    a.white--text.adock-link(
                      @click="$vuetify.goTo('#facilities')"
                    ) Autres établissements
          v-col.pr-2(v-if="carrier.objectif_co2")
            img.adock-objectif-co2.elevation-16(
              v-if="carrier.objectif_co2 === 'ENLISTED'"
              src="@/assets/logo-objectif-co2-charte.jpg"
            )
            img.adock-objectif-co2.elevation-16(
              v-if="carrier.objectif_co2 === 'LABELLED'"
              src="@/assets/logo-objectif-co2-label.jpg"
            )
          v-col.hidden-xs-only
            v-tooltip(
              v-model="copied"
              top
            )
              template(v-slot:activator="{ on }")
                v-btn.clipboard(
                  small
                  fab
                  :data-clipboard-text="detailUrl"
                )
                  v-icon mdi-share-variant
              span Adresse copiée dans le presse-papier (Ctrl + v pour coller).
</template>

<style lang="scss">
.adock-objectif-co2 {
  display: flex;
  border: 6px solid white;
  border-radius: 4px;
  width: 64px;
}
</style>

<script>
import ClipboardJS from "clipboard";
import { mapState } from "vuex";
import roadPicture from "@/assets/road.jpg";
import roadDisabledPicture from "@/assets/road-disabled.jpg";

export default {
  props: {
    carrier: {
      type: Object,
      required: true,
    },

    detailUrl: {
      type: String,
      required: false,
      default: "",
    },
  },

  data() {
    return {
      copied: false,
    };
  },

  created() {
    let clipboard = new ClipboardJS(".clipboard");
    clipboard.on("success", () => {
      this.copied = true;
      setTimeout(() => {
        this.copied = false;
      }, 3000);
    });
  },

  computed: {
    roadPicture() {
      return this.carrier.deleted_at || this.carrier.sirene_closed_at
        ? roadDisabledPicture
        : roadPicture;
    },

    headquarters() {
      if (this.carrier.other_facilities == null) {
        return null;
      }

      const filteredFacilities = this.carrier.other_facilities.filter(
        (facility) => facility.is_siege
      );
      if (filteredFacilities.length > 0) {
        return filteredFacilities[0];
      } else {
        return null;
      }
    },

    isSubsidiary() {
      // When the carrier is a subsidiary, the list of other facilities have a headquarters
      return this.headquarters != null;
    },
    ...mapState(["user"]),
  },

  methods: {
    onClickLoginAndReturn() {
      // Resolve the next URL to use on successful login
      const href = this.$router.resolve({
        name: "accounts__carriers__edit",
        params: { carrierSiret: this.carrier.siret },
      }).href;
      // Pass the href as next param
      this.$router.push({
        name: "accounts__login",
        query: {
          next: href,
        },
      });
    },
  },
};
</script>
