<template lang="pug">
  v-card.adock-card.mt-2
    v-card-title(primary-title)
      h2 Nouveau message
    v-card-text
      v-form(
        ref="form"
        @submit="submit"
      )
        v-alert.adock-show-linebreaks(
          v-if="errorMessage"
          type="error"
          outlined
          text
        ) {{ errorMessage }}
        v-row(
          v-if="user"
          dense
        )
          v-col(cols="12" md="6")
            | À : {{ this.user.full_name }}
        v-row(dense)
          v-col(cols="12" md="6")
            v-text-field(
              label="Sujet *"
              v-model="form.title"
              :error-messages="fieldErrors.title"
            )
        v-row(dense)
          v-col(cols="12" md="8")
            v-textarea(
              placeholder="Votre message *"
              v-model="form.content"
              :error-messages="fieldErrors.content"
            )
        v-row(dense)
          v-col(cols="12")
            v-btn(
              color="primary"
              type="submit"
            ) Envoyer
</template>

<script>
import api from "@/api";
import { scrollToErrorsMixin } from "@/mixins";

export default {
  mixins: [scrollToErrorsMixin],

  props: {
    user: {
      type: Object,
      required: false,
      default: null,
    },
    getRouterNextUrl: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      form: {
        title: "",
        content: "",
      },
    };
  },

  methods: {
    async submit(e) {
      let userId;
      e.preventDefault();

      if (this.user) {
        userId = this.user.id;
      }
      const response = await api.post(api.threadsUrl, {
        title: this.form.title,
        content: this.form.content,
        to_user: userId,
      });
      if (response.status === 200) {
        if (response.data.message) {
          this.$store.commit("MESSAGE_ADD", response.data);
        }
        this.$router.push(this.getRouterNextUrl(response.data.thread_id));
      } else {
        this.setErrorsAndScrollTo(response.data, this.$refs.form);
      }
    },
  },
};
</script>
