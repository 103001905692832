import Vue from "vue";
import VueRouter from "vue-router";

import { CONFIRMATION_ACCOUNT } from "@/confirmations";

import auth from "@/auth";
import { getTracker } from "@/tracker";

Vue.use(VueRouter);

function confirmationAccountPropsFn(route) {
  return {
    confirmationType: CONFIRMATION_ACCOUNT,
    relationId: route.params.userId,
    relationToken: route.params.userToken,
  };
}

// For hardcoded URLs
export const URL_TRANSPORTEURS__AJOUT = "/transporteurs/ajout";
export const URL_TRANSPORTEURS__INSCRIPTION = "/transporteurs/inscription";

const routes = [
  {
    path: "/",
    name: "home",
    component: require("@/views/Home.vue").default,
  },
  {
    path: "/apropos",
    name: "about",
    component: require("@/views/About.vue").default,
  },
  {
    path: "/transporteurs/:confirmationType/:relationId/confirmer/:relationToken",
    component: () => import("@/views/ConfirmationApply.vue"),
    props: true,
  },
  {
    path: "/cgu",
    name: "cgu",
    component: require("@/views/CGU.vue").default,
  },
  {
    path: "/erreur",
    name: "error",
    component: require("@/views/ViewError.vue").default,
    props: true,
  },
  {
    path: "/expediteurs/",
    name: "shippers__search",
    component: require("@/views/ShipperSearch.vue").default,
  },
  {
    // Added 13/05/2020
    path: "/expediteurs/recherche/",
    redirect: { name: "shippers__search" },
  },
  {
    path: "/fc/callback",
    component: require("@/views/FranceConnectCallback.vue").default,
  },
  {
    path: "/fc/postlogout",
    component: require("@/views/FranceConnectPostLogout.vue").default,
  },
  {
    path: "/selftests",
    name: "selftests",
    component: () => import("@/views/SelfTests.vue"),
    meta: { authRequired: true },
  },
  {
    path: "/stats",
    name: "stats",
    component: () => import("@/views/Stats.vue"),
  },
  {
    // Added on 2nd October 2019 (to remove in 3 months?)
    path: "/transporteur/accueil/",
    redirect: { name: "carriers__welcome" },
  },
  {
    // Added 19/11/2019 (QR code)
    path: "/transporteur/:carrierSiret",
    redirect: "/transporteurs/:carrierSiret",
  },
  {
    path: "/transporteurs/",
    name: "carriers__welcome",
    component: require("@/views/CarrierWelcome.vue").default,
  },
  {
    path: URL_TRANSPORTEURS__AJOUT,
    name: "carriers__add",
    component: require("@/views/CarrierAdd.vue").default,
    meta: { authRequired: true },
  },
  {
    path: URL_TRANSPORTEURS__INSCRIPTION,
    name: "carriers__subscribe",
    component: require("@/views/CarrierSubscribe.vue").default,
  },
  {
    path: "/transporteurs/:carrierSiret/inscription",
    component: require("@/views/CarrierSubscribe.vue").default,
    props: true,
  },
  {
    path: "/transporteurs/:carrierSiret",
    name: "carriers__detail",
    component: require("@/views/CarrierDetail.vue").default,
    props: true,
  },
  {
    path: "/utilisateurs/connecter",
    name: "accounts__login",
    component: require("@/views/AccountLogin.vue").default,
  },
  {
    path: "/utilisateurs/recuperer",
    name: "accounts__recover",
    component: () => import("@/views/AccountRecover.vue"),
  },
  {
    path: "/utilisateurs/:email/reinitialiser/:token",
    component: () => import("@/views/AccountPasswordReset.vue"),
    props: true,
  },
  {
    path: "/utilisateurs/attente",
    name: "confirmations__waiting",
    component: require("@/views/ConfirmationWaiting.vue").default,
    props: true,
  },
  {
    path: "/utilisateurs/:userId/confirmer/:userToken",
    component: () => import("@/views/ConfirmationApply.vue"),
    props: confirmationAccountPropsFn,
  },
  {
    path: "/utilisateurs/",
    component: require("@/views/AccountShell.vue").default,
    meta: { authRequired: true },
    children: [
      {
        path: "",
        component: require("@/views/AccountDashboard.vue").default,
        name: "accounts__dashboard",
      },
      {
        path: "transporteurs",
        redirect: { name: "accounts__dashboard" },
      },
      {
        path: "transporteurs/:carrierSiret",
        component: require("@/views/AccountCarrier.vue").default,
        name: "accounts__carrier",
        props: true,
      },
      {
        path: "transporteurs/:carrierSiret/modifier",
        name: "accounts__carriers__edit",
        component: require("@/views/AccountCarrierEdit.vue").default,
        props: true,
      },
      {
        path: "transporteurs/:carrierSiret/attestation",
        name: "accounts__carriers__certificate",
        component: require("@/views/AccountCarrierCertificate.vue").default,
        props: true,
      },
      {
        path: "autorisations/",
        redirect: { name: "accounts__dashboard" },
      },
      {
        path: "messages/",
        component: require("@/views/AccountThreadList.vue").default,
        name: "accounts__threads",
      },
      {
        path: "messages/nouveau",
        component: require("@/components/ThreadNew.vue").default,
        name: "accounts__thread_new",
        props: {
          getRouterNextUrl: (threadId) => ({
            name: "accounts__thread",
            params: {
              threadId,
            },
          }),
        },
      },
      {
        path: "messages/:threadId",
        component: require("@/components/ThreadDetail.vue").default,
        name: "accounts__thread",
        props: true,
      },
      {
        path: "profil",
        component: require("@/views/AccountProfile.vue").default,
        name: "accounts__profile",
      },
    ],
  },
  {
    path: "/gestionnaires/",
    component: require("@/views/ManagerShell.vue").default,
    meta: { authRequired: true },
    children: [
      {
        path: "messages/",
        component: require("@/views/ManagerThreadList.vue").default,
        name: "managers__threads",
      },
      {
        path: "messages/nouveau",
        component: require("@/components/ThreadNew.vue").default,
        name: "managers__thread_new",
        props: (route) => ({
          user: route.params.user,
          getRouterNextUrl: (threadId) => {
            return { name: "managers__threads" };
          },
        }),
      },
      {
        path: "messages/:threadId",
        component: require("@/components/ThreadDetail.vue").default,
        name: "managers__thread",
        props: (route) => ({
          threadId: route.params.threadId,
          forManagers: true,
        }),
      },
    ],
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        // Skip app header + margin
        offset: { x: 0, y: 64 + 16 },
      };
    }
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.authRequired)) {
    if (!auth.getToken()) {
      next({
        name: "accounts__login",
        query: { next: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

// Enable Matomo tracker only for production
if (process.env.NODE_ENV === "production") {
  router.beforeEach((to, from, next) => {
    const tracker = getTracker();
    if (tracker) {
      tracker.trackPageView(to.path);
    }
    next();
  });
}

export function getRouterLocationWhenLogged(user, nextUrl) {
  const location = {};

  if (user == null) {
    return "";
  }

  if (nextUrl) {
    // The user wasn't allowed to access the previous URL but he is logged now;
    location.path = nextUrl;
  } else {
    if (!user.is_confirmed) {
      location.name = "accounts__profile";
    } else if (!user.has_accepted_cgu) {
      // Only possible for new France Connect accounts
      location.name = "cgu";
      // The original request is not kept for new account
    } else {
      location.name = "accounts__dashboard";
    }
  }
  return location;
}

export function getNextUrlFromRoute(route) {
  if (route.query && route.query.next) {
    return route.query.next;
  }
  return "";
}

export function getFranceConnectAuthorizeUrl(route, nextUrl) {
  auth.deleteTokenData();
  const nonce = auth.generateNonce();
  let location = `${process.env.VUE_APP_API_URL}/accounts/fc/authorize?nonce=${nonce}`;
  if (nextUrl == null) {
    nextUrl = getNextUrlFromRoute(route);
  }
  if (nextUrl) {
    location += `&next=${nextUrl}`;
  }
  return location;
}

export default router;
