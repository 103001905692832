<template lang="pug">
v-container.fill-height(fluid)
  v-row(
    justify="center"
    align="center"
  )
    v-col.text-center(
      cols="6"
    )
      div(v-if="message")
        h3.pt-2.adock-show-linebreaks {{ message }}
        br
        v-icon(
          large
        ) mdi-email
      div(v-else)
        p Aucun message en attente
        router-link(:to="{name: 'home'}") Retour à l'accueil
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      required: false,
    },
  },
};
</script>
