<template lang="pug">
div.fill-height.pt-0.adock-section.adock-section-grey
  v-container(fluid)
    v-row
      v-col.pl-0.pt-0(cols="3" lg="2")
        v-navigation-drawer(permanent)
          v-list-item
            v-list-item-content
              v-list-item-title.title Mon espace
          v-divider
          v-list.adock-list-nav(
            v-if="user"
            nav
          )
            v-list-item(
              link
              :to="{name: 'accounts__dashboard'}"
              exact
              :disabled="!user.is_confirmed"
            )
              v-list-item-content
                v-list-item-title Vos entreprises
            v-list-item(
              link
              :to="{name: 'accounts__profile'}"
            )
              v-list-item-content
                v-list-item-title Paramètres
            v-list-item(
              link
              :to="{name: 'accounts__threads'}"
              :disabled="!user.is_confirmed"
            )
              v-list-item-content
                v-list-item-title Messagerie
      v-col(cols="9" lg="10")
        router-view
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["user"]),
  },
};
</script>
