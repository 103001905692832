<template lang="pug">
  v-card.mt-2.adock-card(v-if="user")
    v-container
      v-row
        v-col.hidden-sm-and-down(md="2")
          v-icon(size="120px") mdi-account-circle
        v-col(cols="12" md="10")
          h2 {{ displayUser }}
          p {{ user.email }}
          p(v-if="user.provider_data")
            | Adresse : {{ user.provider_data.address }}
            span(v-if="user.provider_data.phone") Téléphone : {{ user.provider_data.phone }}
            |
            br
            | Créé le : {{ localeDateJoined }} via « {{ user.provider_display }} »
          p(v-if="!user.is_confirmed")
            v-alert(
              type="error"
              outlined
              text
            )
              | Vous devez confirmer votre adresse e-mail pour utiliser le service.
              br
              | Un lien de confirmation a été envoyé à « {{ user.email }} ».
              br
              | Si vous n'avez pas reçu ce lien dans votre boîte de réception ou vos indésirables, vous
              | pouvez demander de le recevoir à nouveau.
              br
              v-btn.mt-2(
                @click="mailConfirmationLink"
              ) Recevoir à nouveau

          p(v-if="user.has_accepted_cgu")
            | Vous avez accepté la dernière version des
            |
            router-link(:to="{name: 'cgu'}") CGU
          p(v-else)
            v-alert.mb-0(
              type="warning"
              outlined
              text
            )
              | Vous devez accepter les dernières
              |
              router-link(:to="{name: 'cgu'}") Conditions Générales d'Utilisation
              |
              | pour utiliser le service.
          v-btn.mt-6(@click="logOutUser") Se déconnecter
      v-form(
        v-model="isValid"
        ref="form"
        @submit="submit"
      )
        v-row
          v-col
            v-alert.adock-show-linebreaks(
              v-if="errorMessage"
              type="error"
              outlined
              text
            ) {{ errorMessage }}
        v-row(dense)
          v-col(md="6")
            v-text-field(
              v-if="isADockAccount"
              label="Prénom *"
              v-model="form.firstName"
              :rules="requiredRules"
              validate-on-blur
              :error-messages="fieldErrors.first_name"
              data-cy="inputFirstName"
              hint="* Champ obligatoire"
            )
        v-row(dense)
          v-col(md="6")
            v-text-field(
              v-if="isADockAccount"
              label="Nom *"
              v-model="form.lastName"
              :rules="requiredRules"
              validate-on-blur
              :error-messages="fieldErrors.last_name"
              data-cy="inputLastName"
              hint="* Champ obligatoire"
            )
        v-row(dense)
          v-col(md="6")
            v-text-field(
              v-if="isADockAccount"
              label="Adresse e-mail *"
              v-model="form.email"
              :rules="emailRules"
              validate-on-blur
              :error-messages="fieldErrors.email"
            )
        v-row(dense)
          v-col(md="6")
            v-text-field(
              label="Nouveau mot de passe"
              v-model="form.password"
              @input="fieldErrors.password = null"
              :rules="optionalPasswordRules"
              :append-icon="isPlainPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="() => (isPlainPassword = !isPlainPassword)"
              :type="isPlainPassword ? 'text': 'password'"
              counter
              validate-on-blur
              :error-messages="fieldErrors.password"
              hint="Laissez ce champ vide pour conserver le mot de passe actuel."
            )
        v-row(dense)
          v-col
            v-checkbox(
              v-if="!user.has_accepted_cgu"
              v-model="form.hasAcceptedCGU"
              @change="fieldErrors.has_accepted_cgu = null"
              :error-messages="fieldErrors.has_accepted_cgu"
              data-cy="inputHasAcceptedCGU"
              hide-details
            )
              template(v-slot:label="")
                span
                  | Je certifie avoir lu et accepté les
                  |
                  router-link(:to="{name: 'cgu'}") Conditions générales d'utilisation
                  | *
            v-checkbox(
              v-if="!user.has_accepted_cgu"
              v-model="form.isEntitled"
              label="Je certifie être habilité(e) à réaliser les démarches au nom de l'entreprise *"
              :error-messages="fieldErrors.has_accepted_cgu"
              data-cy="inputIsEntitled"
              hide-details
            )
        v-row(dense)
          v-col
            span.caption * champs obligatoires
            v-checkbox(
              v-model="form.hasSubscribedNewsletter"
              @change="fieldErrors.has_subscribed_newsletter = null"
              label="J'accepte d'être informé(e) des nouveautés et actualités d'A Dock"
              hide-details
            )
        v-row
          v-col
            v-btn(
              color="primary"
              :disabled="isDisabled"
              type="submit"
            ) Enregister
</template>

<script>
import { mapState } from "vuex";

import api from "@/api";
import {
  displayUserMixin,
  emailRules,
  optionalPasswordRules,
  requiredRules,
  scrollToErrorsMixin,
} from "@/mixins";

const CONFIRMATION_HEADERS = [
  {
    text: "Date",
    align: "left",
    value: "created_at",
  },
  {
    text: "Enseigne",
    align: "left",
    value: "carrier_enseigne",
  },
  {
    text: "Confirmation",
    align: "left",
    value: "title",
  },
  {
    text: "Description",
    align: "text",
    value: "description",
  },
];

function setFormWithUser(form, user) {
  form.hasAcceptedCGU = user.has_accepted_cgu;
  form.isEntitled = user.has_accepted_cgu;
  form.hasSubscribedNewsletter = user.has_subscribed_newsletter;
  form.email = user.email;
  form.firstName = user.first_name;
  form.lastName = user.last_name;
}

export default {
  mixins: [displayUserMixin, scrollToErrorsMixin],

  data() {
    const form = {
      hasAcceptedCGU: false,
      // Only used to disable the submit from UI
      isEntitled: false,
      hasSubscribedNewsletter: false,
      email: "",
      firstName: "",
      lastName: "",
      password: "",
    };

    if (this.$store.state.user) {
      setFormWithUser(form, this.$store.state.user);
    }

    return {
      isPlainPassword: false,
      isValid: false,
      form,
    };
  },

  mounted() {
    this.$store.dispatch("loadUserProfile");
  },

  created() {
    this.CONFIRMATION_HEADERS = CONFIRMATION_HEADERS;
    this.emailRules = emailRules;
    this.optionalPasswordRules = optionalPasswordRules;
    this.requiredRules = requiredRules;
  },

  watch: {
    user: function (newValue) {
      setFormWithUser(this.form, newValue);
    },
  },

  computed: {
    isDisabled() {
      // Only for submit button
      const statuses = [
        this.form.firstName !== "" &&
          this.form.lastName !== "" &&
          this.form.hasAcceptedCGU !== false &&
          this.form.isEntitled !== false,
      ];
      this.emailRules.forEach((rule) => statuses.push(rule(this.form.email)));
      return !statuses.every((v) => v === true);
    },

    isADockAccount() {
      return this.user && this.user.provider === "AD";
    },

    localeDateJoined() {
      return new Date(this.user.date_joined).toLocaleDateString();
    },

    localeLastLogin() {
      return this.user.last_login
        ? new Date(this.user.last_login).toLocaleDateString()
        : new Date().toLocaleDateString();
    },
    ...mapState(["choices"]),
  },

  methods: {
    logOutUser() {
      this.$store.dispatch("logOutUser");
      this.$router.push({ path: "/" });
    },

    getPayloadFromForm() {
      const payload = {
        has_accepted_cgu: this.form.hasAcceptedCGU,
        has_subscribed_newsletter: this.form.hasSubscribedNewsletter,
      };
      if (this.isADockAccount) {
        payload["email"] = this.form.email;
        payload["first_name"] = this.form.firstName;
        payload["last_name"] = this.form.lastName;
        if (this.form.password) {
          payload["password"] = this.form.password;
        }
      }
      return payload;
    },

    async mailConfirmationLink() {
      const response = await api.post(api.accountMailConfirmationLinkUrl);
      if (response.status === 200) {
        this.$router.push({
          name: "confirmations__waiting",
          params: {
            message: response.data.confirmation.message,
          },
        });
      }
    },

    async submit(e) {
      e.preventDefault();
      if (this.$refs.form.validate()) {
        this.fieldErrors = {};
        const payload = this.getPayloadFromForm();
        const response = await api.patch(api.profileUrl, payload);
        if (response.status === 200) {
          this.$store.commit("MESSAGE_ADD", {
            message:
              "Vos paramètres utilisateur ont été enregistrés avec succès.",
          });
          // Update user
          this.$store.commit("USER_SET", response.data);
        } else {
          this.setErrorsAndScrollTo(response.data, this.$refs.form);
        }
      }
    },
  },
};
</script>
