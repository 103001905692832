<template lang="pug">
v-card(
  ref="searchForm"
).adock-card
  v-alert.adock-show-linebreaks(
    v-if="errorMessage"
    type="error"
    outlined
    text
  ) {{ errorMessage }}
  v-container(align="baseline")
    v-row(dense)
      v-col
        v-text-field(
          :label="searchLabel"
          v-model.trim="searchQuery"
          @keyup.enter="search"
          data-cy="carrierInputSearch"
        )
    v-row(dense)
      v-col(align="right")
        v-btn.mr-4(@click.native="clear") Effacer
        v-btn(large color="primary" @click.native="search") Chercher
</template>

<script>
import api from "@/api";

const SEARCH_LIMIT = 5;

export default {
  props: {
    searchLabel: {
      type: String,
      required: true,
    },
    searchUrl: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      searchQuery: "",
      errorMessage: null,
    };
  },

  methods: {
    async search() {
      // Remove error message as soon as the user clicks.
      this.errorMessage = null;
      this.$emit("is-searching", true);
      // For event
      let carriers = null;
      let limit = null;
      let searchParams = "";

      const params = {};
      if (this.searchQuery) {
        params["q"] = this.searchQuery;
      }
      params["limit"] = SEARCH_LIMIT;

      const response = await api.get(this.searchUrl, params);
      if (response.status === 200) {
        carriers = response.data.carriers;
        // Build an object with search parameters to display them to the user with the results
        searchParams = { q: this.searchQuery };
        limit = response.data.limit || 0;
      } else {
        if (response.data.message) {
          this.errorMessage = response.data.message;
        }
        carriers = null;
        limit = null;
      }

      this.$emit("search", {
        carriers,
        params: searchParams,
        limit,
      });
      this.$emit("is-searching", false);

      this.$nextTick(() => {
        this.$vuetify.goTo(this.$refs.searchForm, {
          offset: 16,
        });
      });
    },

    clear() {
      this.searchQuery = "";
      this.$emit("search", {
        carriers: null,
        params: null,
        limit: null,
      });
    },
  },
};
</script>
