import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.threads,"sort-by":"data.created_at","items-per-page":15},on:{"click:row":_vm.onRowClick},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("asLocaleDate")(item.created_at)))]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{staticClass:"ma-1",attrs:{"to":_vm.getRouterThreadUrl(item.id),"depressed":""}},[_c(VIcon,{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-eye")]),_vm._v("Lire")],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }