<template lang="pug">
div
  AccountCarrierList
</template>

<script>
import AccountCarrierList from "@/components/AccountCarrierList.vue";

export default {
  components: {
    AccountCarrierList,
  },
};
</script>
