import { mapState } from "vuex";

import api from "@/api";
import { routeGet } from "@/routeLoaders";

export const displayUserMixin = {
  computed: {
    displayUser() {
      if (this.user == null) {
        return "";
      }

      return this.user.first_name
        ? `${this.user.first_name} ${this.user.last_name}`
        : this.user.email;
    },
    ...mapState(["user"]),
  },
};

export const requiredRules = [
  (v) => (v != null && v !== "") || "Le champ est requis.",
];
export const trustyRules = [(v) => !!v || "Le champ est requis."];
export const emailRules = [
  (v) => !!v || "Une adresse e-mail est requise.",
  (v) => /.+@.+/.test(v) || "L'adresse e-mail n'est pas valide.",
];
export const numberRules = [
  (v) => !isNaN(parseInt(v)) || "Le nombre n'est pas valide.",
];
export const passwordRules = [
  (v) => !!v || "Un mot de passe est requis.",
  (v) =>
    (v && v.length >= 8) || "Le mot de passe doit au moins avoir 8 caractères.",
];
export const optionalPasswordRules = [
  (v) =>
    !v ||
    v.length === 0 ||
    v.length >= 8 ||
    "Le mot de passe doit au moins avoir 8 caractères.",
];

export const scrollToErrorsMixin = {
  data() {
    return {
      errorMessage: null,
      fieldErrors: {},
    };
  },

  methods: {
    setErrorsAndScrollTo(data, refMainContent) {
      let scrollToTarget = null;
      this.errorMessage = null;
      this.fieldErrors = {};

      if (data.errors) {
        // Form error
        this.fieldErrors = data.errors;
        if (data.errors.__all__) {
          this.errorMessage = data.errors.__all__.join(" ");
          scrollToTarget = refMainContent;
        }
      }

      if (data.message) {
        // Global error
        this.errorMessage = data.message;
        scrollToTarget = refMainContent;
      }

      if (this.errorMessage === null && this.fieldErrors === null) {
        this.errorMessage = "Erreur du serveur.";
      }

      if (!scrollToTarget) {
        scrollToTarget = ".error--text";
      }

      if (scrollToTarget) {
        this.$nextTick(() => {
          this.$vuetify.goTo(scrollToTarget, {
            offset: 0,
          });
        });
      }
    },
  },
};

function routeLoadCarrier(routeTo, routeFrom, onSuccess) {
  const url = api.getCarrierUrl(routeTo.params.carrierSiret);
  return routeGet(routeTo, routeFrom, onSuccess, url);
}

export const routeLoadCarrierMixin = {
  async beforeRouteEnter(routeTo, routeFrom, next) {
    if (routeTo.params.carrier) {
      next();
    } else {
      next(
        await routeLoadCarrier(routeTo, routeFrom, (data) => {
          routeTo.params.carrier = data.carrier;
        })
      );
    }
  },

  async beforeRouteUpdate(routeTo, routeFrom, next) {
    next(
      await routeLoadCarrier(routeTo, routeFrom, (data) => {
        routeTo.params.carrier = data.carrier;
      })
    );
  },
};
