<template lang="pug">
div
    CarrierSearchForm(
      :search-label="searchLabel"
      :search-url="searchUrl"
      @is-searching="isSearching = $event"
      @search="searchResult = $event"
    )
    CarrierSearchSelect(
      :searchParams="searchResult.params"
      :searchResponseIsEmpty="searchResponseIsEmpty"
      :carriers="searchResult.carriers"
      :limit="searchResult.limit"
      @carrier-selected="$emit('carrier-selected', $event)"
    )
</template>

<script>
import api from "@/api";
import CarrierSearchSelect from "@/components/CarrierSearchSelect.vue";
import CarrierSearchForm from "@/components/CarrierSearchForm.vue";

export default {
  components: {
    CarrierSearchForm,
    CarrierSearchSelect,
  },

  props: {
    isRegistered: {
      // The company is registered as carrier
      type: Boolean,
      required: false,
      default: true,
    },
    canEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      isSearching: false,
      searchResult: {},
    };
  },

  computed: {
    searchUrl() {
      if (this.isRegistered) {
        return this.canEdit
          ? api.searchCarriersCanEditUrl
          : api.searchCarriersUrl;
      } else {
        return api.searchSireneUrl;
      }
    },

    searchLabel() {
      if (this.isRegistered) {
        return "Nom, département ou SIREN de l'entreprise";
      } else {
        return "SIREN de l'entreprise";
      }
    },

    searchResponseIsEmpty() {
      return (
        !this.isSearching &&
        this.searchResult.carriers != null &&
        this.searchResult.carriers.length === 0
      );
    },
  },
};
</script>
