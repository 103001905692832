import auth from "@/auth";
import api from "@/api";

export const actions = {
  // Core
  loadMeta: async ({ commit }) => {
    const response = await api.get(api.metaUrl);
    if (response.status === 200) {
      commit("CHOICES_SET", response.data);
      commit("OPTIONS_SET", response.data);
      commit("STATS_SET", response.data);
    }
  },

  notifyUserLogged({ commit, state }) {
    commit("MESSAGE_ADD", {
      message: `Connecté en tant que « ${state.user.email} » via ${state.user.provider_display}.`,
    });
  },

  loadUserProfile: async ({ commit }, { withErrorHandling = true } = {}) => {
    const response = await api.get(api.profileUrl, {}, { withErrorHandling });
    if (response.status !== 200) {
      return false;
    }

    commit("USER_SET", response.data);
    return true;
  },

  logOutUser: async ({ commit }) => {
    let response = null;
    const idToken = auth.getIdToken();

    if (idToken) {
      // Logout from FC
      response = await api.get(api.franceConnectLogoutUrl, {
        id_token: idToken,
      });
    }

    auth.deleteTokenData();
    commit("USER_DELETE");

    if (response && response.status === 302) {
      // Redirect to FC that will redirect to UI logout view
      window.location = response.data.url;
    }
  },
};

export default actions;
