<template lang="pug">
v-form(
  v-model="isValid"
  ref="form"
  @submit="submit"
)
  v-alert.adock-show-linebreaks(
    v-if="errorMessage"
    type="error"
    outlined
    text
  ) {{ errorMessage }}
  span.caption Les champs avec un * sont obligatoires.
  v-text-field(
    label="Prénom *"
    v-model="firstName"
    :rules="requiredRules"
    validate-on-blur
    :error-messages="fieldErrors.first_name"
    data-cy="inputFirstName"
    hint="* Champ obligatoire"
  )
  v-text-field(
    label="Nom *"
    v-model="lastName"
    :rules="requiredRules"
    validate-on-blur
    :error-messages="fieldErrors.last_name"
    data-cy="inputLastName"
    hint="* Champ obligatoire"
  )
  v-text-field(
    label="Adresse e-mail *"
    type="email"
    v-model="email"
    @input="fieldErrors.email = null"
    :rules="emailRules"
    validate-on-blur
    :error-messages="fieldErrors.email"
    data-cy="inputEmail"
    hint="Cette adresse confidentielle sera utilisée pour les démarches administratives avec le service A Dock. Elle ne sera pas rendue publique."
  )
  v-text-field(
    label="Choisissez un mot de passe *"
    type="password"
    v-model="password"
    @input="fieldErrors.password = null"
    :rules="passwordRules"
    :append-icon="isPlainPassword ? 'mdi-eye' : 'mdi-eye-off'"
    @click:append="() => (isPlainPassword = !isPlainPassword)"
    :type="isPlainPassword ? 'text': 'password'"
    counter
    validate-on-blur
    :error-messages="fieldErrors.password"
    data-cy="inputPassword"
    hint="* Champ obligatoire"
  )
  v-checkbox(
    v-model="hasAcceptedCGU"
    @input="fieldErrors.has_accepted_cgu = null"
    :error-messages="fieldErrors.has_accepted_cgu"
    data-cy="inputHasAcceptedCGU"
    hide-details
  )
    template(v-slot:label="")
      span
        | Je certifie avoir lu et accepté les
        |
        router-link(:to="{name: 'cgu'}") Conditions générales d'utilisation
        |
        | *
  v-checkbox(
    v-model="isEntitled"
    label="Je certifie être habilité(e) à réaliser les démarches au nom de l'entreprise *"
    data-cy="inputIsEntitled"
    hide-details
  )
  v-checkbox(
    v-model="hasSubscribedNewsletter"
    @change="fieldErrors.has_subscribed_newsletter = null"
    label="J'accepte d'être informé(e) des nouveautés et actualités d'A Dock"
    hide-details
  )
  v-row
    v-col(align="right")
      slot(
        name="submit-button"
        v-bind:submit="submit"
        v-bind:isDisabled="isDisabled"
      )
        v-btn(
          color="primary"
          @click="submit"
          :disabled="isDisabled"
          type="submit"
        ) Créer le compte
</template>

<script>
import auth from "@/auth";
import api from "@/api";
import { emailRules, passwordRules, requiredRules } from "@/mixins";

export default {
  data() {
    return {
      email: "",
      errorMessage: "",
      fieldErrors: {},
      firstName: "",
      hasAcceptedCGU: false,
      hasSubscribedNewsletter: false,
      isEntitled: false,
      isPlainPassword: false,
      isValid: false,
      lastName: "",
      password: "",
    };
  },

  created() {
    this.emailRules = emailRules;
    this.passwordRules = passwordRules;
    this.requiredRules = requiredRules;
  },

  computed: {
    isDisabled() {
      // Only for submit button
      const statuses = [
        this.firstName !== "" &&
          this.lastName !== "" &&
          this.hasAcceptedCGU !== false &&
          this.isEntitled !== false,
      ];
      this.emailRules.forEach((rule) => statuses.push(rule(this.email)));
      this.passwordRules.forEach((rule) => statuses.push(rule(this.password)));
      return !statuses.every((v) => v === true);
    },
  },

  methods: {
    getPayloadFromForm() {
      return {
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email,
        password: this.password,
        has_accepted_cgu: this.hasAcceptedCGU,
        has_subscribed_newsletter: this.hasSubscribedNewsletter,
      };
    },

    async submit(e) {
      e.preventDefault();
      if (this.$refs.form.validate()) {
        const payload = this.getPayloadFromForm();
        await this.$store.dispatch("logOutUser");
        const response = await api.post(api.loginCreateUrl, payload);
        if (response.status === 200) {
          auth.setTokenData(response.data);
          this.errorMessage = "";
          this.$emit("account-created", {
            account: {
              firstName: this.firstName,
              lastName: this.lastName,
              email: this.email,
            },
            confirmation: response.data.confirmation,
          });
        } else {
          if (response.data.errors) {
            this.fieldErrors = response.data.errors;
          }
        }
      }
    },
  },
};
</script>
