<template lang="pug">
  v-card.adock-card.mt-2(v-if="thread")
    v-card-title(primary-title)
      h2 {{ thread.title }}
    v-card-text
      p {{ thread.created_at | asLocaleDate }} - {{ isClosed ? 'Traité' : 'En cours' }}
    v-container
      v-timeline(:dense="$vuetify.breakpoint.smAndDown")
        v-timeline-item(
          v-for="message, index in thread.messages"
          :key="message.id"
          :color="getMessageColor(index)"
          :right="fromMe(message)"
          :left="!fromMe(message)"
        )
          template(v-slot:opposite="")
            span.title.font-weight-bold {{ message.created_at | asLocaleDate }}
          v-card.elevation-2
            v-card-title.title {{ message.from_user.full_name }}
            v-card-subtitle {{ message.from_user.email }}
            v-card-text.adock-show-linebreaks {{ message.content }}
      v-form(
        v-if="!isClosed"
        ref="form"
        @submit="submit"
      )
        v-row(
          dense
          justify="end"
        )
          v-col(cols="12" md="8")
            v-textarea(
              placeholder="Votre message *"
              v-model="form.content"
              :error-messages="fieldErrors.content"
            )
        v-row(
          dense
          justify="end"
        )
          v-col(align="right")
            v-btn.mr-5(
              color="primary"
              type="submit"
            ) Envoyer
            v-btn(
              color="success"
              @click="closeThread"
            ) Clore la discussion
</template>

<script>
import api from "@/api";
import { scrollToErrorsMixin } from "@/mixins";
import { mapState } from "vuex";

export default {
  mixins: [scrollToErrorsMixin],

  props: {
    threadId: {
      required: true,
    },
    forManagers: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      thread: null,
      form: {
        content: "",
      },
    };
  },

  created() {
    this.url = api.getThreadUrl(this.threadId);
    this.loadThread();
  },

  watch: {
    threadId() {
      this.url = api.getThreadUrl(this.threadId);
      this.loadThread();
    },
  },

  computed: {
    isClosed() {
      return this.thread.state === "CLOSED";
    },
    ...mapState(["user"]),
  },

  methods: {
    fromMe(message) {
      return message.from_user.email === this.user.email;
    },

    async loadThread() {
      const response = await api.get(this.url);
      if (response.status === 200) {
        this.thread = response.data.thread;
      }
    },

    async submit(e) {
      if (e) e.preventDefault();
      const response = await api.patch(this.url, {
        content: this.form.content,
      });
      if (response.status === 200) {
        this.thread = response.data.thread;
        this.form.content = "";
        if (response.data.message) {
          this.$store.commit("MESSAGE_ADD", response.data);
        }
      } else {
        this.setErrorsAndScrollTo(response.data, this.$refs.form);
      }
    },

    async closeThread() {
      if (this.form.content) {
        this.submit();
      }

      const response = await api.delete(this.url);
      if (response.status === 200) {
        if (response.data.message) {
          this.$store.commit("MESSAGE_ADD", response.data);
        }

        let url_name;
        if (this.forManagers) {
          url_name = "managers__threads";
        } else {
          url_name = "accounts__threads";
        }
        this.$router.push({
          url_name,
        });
      } else {
        this.setErrorsAndScrollTo(response.data, this.$refs.form);
      }
    },

    getMessageColor(index) {
      if (index === 0) {
        return "orange";
      } else if (index === this.thread.messages.length - 1) {
        if (this.isClosed) {
          return "success";
        } else {
          return "primary";
        }
      } else {
        return "grey";
      }
    },
  },
};
</script>
