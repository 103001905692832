<template lang="pug">
  v-card.adock-card.pa-0
    v-row
      v-col(cols="12" offset-md="1" md="10")
        v-container
          v-row
            v-col
              h3 Modification de l'entreprise {{ carrier.enseigne }}
          CarrierEditForm(
            :carrier="carrier"
            @carrier-saved="redirect($event)"
            :cancelUrl="{name: 'accounts__carrier', params: { carrierSiret: carrier.siret }}"
          )
</template>

<script>
import { routeLoadCarrierMixin } from "@/mixins";

import CarrierCardHeader from "@/components/CarrierCardHeader.vue";
import CarrierEditForm from "@/components/CarrierEditForm.vue";

export default {
  mixins: [routeLoadCarrierMixin],

  props: {
    carrier: {
      type: Object,
      required: true,
    },
  },

  components: {
    CarrierCardHeader,
    CarrierEditForm,
  },

  methods: {
    redirect(data) {
      if (data.confirmation) {
        this.$router.push({
          name: "confirmations__waiting",
          params: {
            message: data.confirmation.message,
          },
        });
      } else {
        this.$store.commit("MESSAGE_ADD", data);
        this.$router.push({
          name: "accounts__carrier",
          params: { carrierSiret: this.carrier.siret },
        });
      }
    },
  },
};
</script>
