import Vue from "vue";
import Vuex from "vuex";

import actions from "./actions";
import { mutations, state } from "./mutations";

Vue.use(Vuex);

export const storeOptions = {
  state,
  mutations,
  actions,
  strict: process.env.NODE_ENV !== "production",
};

const store = new Vuex.Store(storeOptions);

export default store;
