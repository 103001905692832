import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VImg,{staticClass:"white--text",attrs:{"src":_vm.roadPicture,"height":"200px"}},[_c(VContainer,{attrs:{"fill-height":""}},[_c(VRow,{staticClass:"mt-4 mx-2",attrs:{"align":"end"}},[_c(VCol,[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"10"}},[_c(VRow,{attrs:{"align":"start","justify":"start","no-gutters":""}},[_c(VCol,[_c('h2',[_vm._v(_vm._s(_vm.carrier.enseigne))]),_c('span',{staticClass:"white--text text--darken-1"},[_vm._v(_vm._s(_vm.carrier.libelle_ape))]),_c('br'),(_vm.isSubsidiary)?_c('span',{staticClass:"white--text text--darken-1"},[_vm._v("Filiale de "),_c('router-link',{staticClass:"white--text",attrs:{"to":{name: 'carriers__detail', params: {carrierSiret: this.headquarters.siret }}}},[_vm._v(_vm._s(_vm.headquarters.enseigne))])],1):_c('span',{staticClass:"white--text text--darken-1"},[_c(VIcon,{staticClass:"mr-1",attrs:{"dark":""}},[_vm._v("mdi-domain")]),_vm._v("Siège de l'entreprise"),(_vm.carrier.other_facilities.length > 0)?_c('span',[_vm._v(" - "),_c('a',{staticClass:"white--text adock-link",on:{"click":function($event){return _vm.$vuetify.goTo('#facilities')}}},[_vm._v("Autres établissements")])]):_vm._e()],1)])],1)],1),(_vm.carrier.objectif_co2)?_c(VCol,{staticClass:"pr-2"},[(_vm.carrier.objectif_co2 === 'ENLISTED')?_c('img',{staticClass:"adock-objectif-co2 elevation-16",attrs:{"src":require("@/assets/logo-objectif-co2-charte.jpg")}}):_vm._e(),(_vm.carrier.objectif_co2 === 'LABELLED')?_c('img',{staticClass:"adock-objectif-co2 elevation-16",attrs:{"src":require("@/assets/logo-objectif-co2-label.jpg")}}):_vm._e()]):_vm._e(),_c(VCol,{staticClass:"hidden-xs-only"},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,{staticClass:"clipboard",attrs:{"small":"","fab":"","data-clipboard-text":_vm.detailUrl}},[_c(VIcon,[_vm._v("mdi-share-variant")])],1)]}}]),model:{value:(_vm.copied),callback:function ($$v) {_vm.copied=$$v},expression:"copied"}},[_c('span',[_vm._v("Adresse copiée dans le presse-papier (Ctrl + v pour coller).")])])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }