<template lang="pug">
  div
    v-card.mt-2.adock-card
      v-col
        h2 {{ carrier.enseigne }}
        h4 {{ carrier.libelle_ape }}
        h4 SIREN {{ carrier.siret | asSiren }}
      v-col
        v-btn(:to="{name: 'carriers__detail', params: {carrierSiret: carrier.siret}}") Voir la fiche publique
      v-alert(
        :value="!!carrier.deleted_at"
        type="warning"
        outlined
        text
      )
        | Cet établissement est absent du registre transports de marchandises
        | depuis le {{ carrier.deleted_at | asLocaleDate }}. Cela peut faire
        | suite à l'expiration de votre licence de transport.
      v-alert(
        :value="!carrier.sirene_exists"
        type="warning"
        outlined
        text
      )
        | Cet établissement n'est pas publiée dans la base Sirene de l'Insee.
        | La cause peut être l'opposition au démarchage commercial.
        | Nous vous invitons à contacter le
        |
        a(href="https://www.service-public.fr/professionnels-entreprises/vosdroits/F24023") Centre de formalités des entreprises
        |
        | dont vous dépendez.
      v-alert(
        :value="!!carrier.sirene_closed_at"
        type="warning"
        outlined
        text
      )
        | Cet établissement est fermé depuis le {{ carrier.sirene_closed_at | asLocaleDate }}.
        | L'information provient de la base Sirene.
    h4.mt-6.mb-2 Contact et activité
    v-card.adock-card
      v-container.pa-6
        v-row
          v-col(cols="12" sm="6")
            v-row Téléphone
            v-row
              a(
                v-if="carrier.telephone"
                :href="'tel:' + carrier.telephone"
              ) {{ carrier.telephone }}
              span(v-else) Non renseigné
            v-row.mt-4 Adresse e-mail
            v-row
              a(
                v-if="carrier.email"
                :href="'mailto:' + carrier.email"
              ) {{ carrier.email }}
              span(v-else) Non renseigné
            v-row.mt-4 Site Web
            v-row
              a(
                v-if="carrier.website"
                :href="carrier.website"
              ) {{ carrier.website }}
              span(v-else) Non renseigné
          v-col(cols="12" sm="6")
            v-row Aire de travail
            v-row {{ choices.workingAreas[carrier.working_area] }}
            template(
              v-if="carrier.working_area === 'DEPARTEMENT' || carrier.working_area === 'REGION'"
            )
              v-row.mt-4 Départements livrés
              v-row {{ carrier.working_area_departements | asJoinedString }}
            v-row.mt-4 Spécialités
            v-row {{ displaySpecialities }}
            v-row.mt-4 Description de l'activité
            v-row {{ carrier.description || "Non renseigné" }}
          v-row
            v-col
              v-btn.mt-4(
                :to="{name: 'accounts__carriers__edit', params: {carrierSiret: carrier.siret}}"
              ) Modifier
    h4.mt-6.mb-2 Licences de transport
    v-card.adock-card
      v-container.pa-6
        v-row
          p
            | Vos démarches ne sont plus accessibles sur cette application, vous devez à nouveau
            | vous adresser à votre DREAL depuis le 23 août 2021.
        v-row(v-if="carrier.lti_numero")
          v-col(cols="12" md="5")
            p.mb-0
              span.font-weight-bold Licence LTI (léger)
              br
              | Numéro : {{ carrier.lti_numero }}
            p.mb-0
              | Validité : 
              | {{ carrier.lti_date_debut | asLocaleDate }} au
              span(:class="classExpirationDateLTI")  {{ carrier.lti_date_fin | asLocaleDate }}
            p.mb-0
              | Nombre : 
              span {{ carrier.lti_nombre }}
        v-row(v-else)
          v-col
            p.mb-0 Aucune licence LTI (léger)
        v-row(v-if="carrier.lc_numero")
          v-col(cols="12" md="5")
            p.mb-0
              span.font-weight-bold Licence LC (lourd, + 3,5 tonnes)
              br
              | Numéro : {{ carrier.lc_numero }}
            p.mb-0
              | Validité :
              | {{ carrier.lc_date_debut | asLocaleDate }} au
              span(:class="classExpirationDateLC")  {{ carrier.lc_date_fin | asLocaleDate }}
            p.mb-0
              | Nombre : 
              span {{ carrier.lc_nombre }}
        v-row(v-else)
          v-col
            p.mb-0 Aucune licence LC (lourd, + 3,5 tonnes)
        v-row
          v-col
            p.adock-grey-text.font-italic(v-if="!licensesAlmostExpired")
              span(v-if="licensesBeginInFuture")
                | Votre renouvellement de licences a été traité.
                |  Si vous n'avez pas encore reçu vos licences, vous les recevrez très prochainement par courrier.
              span(v-else)
                | La validité des licences actuelles est supérieure à 3 mois et il n'est pas encore
                |  possible d'effectuer une demande de renouvellement.
    h4#carrier-documents.mt-6.mb-2 Documents
    v-card.adock-card
      v-container.pa-6
        template(v-if="carrier.latest_certificate")
          v-row
            v-col
              | {{ carrier.latest_certificate.kind_display }}
              br
              span.adock-grey-text {{ carrier.latest_certificate.created_at | asLocaleDate }}
          v-row
            v-col
              v-btn(
                large
                :href="API_URL + carrier.latest_certificate.pdf_url"
              ) Télécharger
                v-icon(right) mdi-package-down
        v-row(v-else)
          v-col
            span.adock-grey-text Aucun document
        v-row
          v-col
            v-btn(
              :to="{name: 'accounts__carriers__certificate', params: {carrierSiret: carrier.siret}}"
            )
              span(v-if="carrier.latest_certificate") Remplacer l'attestation actuelle
              span(v-else) Générer une attestation d'emploi ou non emploi
    h4.mt-6.mb-2 Historique
    v-card.adock-card
      v-container.pa-6
        v-timeline
          v-timeline-item(
            v-for="log in carrier.logs"
            :key="log.date"
            small
          )
            template(v-slot:opposite="")
              span.title.font-weight-bold {{ log.date | asLocaleDate }}
            div.py-4.pt-0
              h2.mb-0.title.font-weight-light {{ log.title }}
              p.mb-0 {{ log.description }}
              span.caption par {{ log.created_by }}
              v-alert(
                v-if="log.type === 'EDITABLE' && log.confirmed_at == null"
                dense
                type="warning"
                outlined
                text
              ) En attente de confirmation. Veuillez cliquer sur le lien envoyé par e-mail.
    h4.mt-6 Comptes associés
    v-row
      v-col(
        v-for="user in carrier.users"
        :key="user.email"
        cols="4"
      )
        v-card
          v-card-title {{ user.first_name }} {{ user.last_name }}
          v-card-subtitle {{ user.email }}
</template>

<script>
import { mapState } from "vuex";

import api from "@/api";
import { routeGet } from "@/routeLoaders";
import { getClassExpirationDate } from "@/carriers";

function routeLoadCarrierManagement(routeTo, routeFrom, onSuccess) {
  const url = api.getCarrierManagementUrl(routeTo.params.carrierSiret);
  return routeGet(routeTo, routeFrom, onSuccess, url);
}

export default {
  props: {
    carrier: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      licensesLoading: {
        lc: false,
        lti: false,
      },
    };
  },

  created() {
    this.API_URL = process.env.VUE_APP_API_URL;
  },

  async beforeRouteEnter(routeTo, routeFrom, next) {
    if (routeTo.params.carrier) {
      next();
    } else {
      next(
        await routeLoadCarrierManagement(routeTo, routeFrom, (data) => {
          routeTo.params.carrier = data.carrier;
        })
      );
    }
  },

  async beforeRouteUpdate(routeTo, routeFrom, next) {
    // Called on click in sidebar
    next(
      await routeLoadCarrierManagement(routeTo, routeFrom, (data) => {
        routeTo.params.carrier = data.carrier;
      })
    );
  },

  computed: {
    displaySpecialities() {
      if (this.carrier.specialities == null) {
        return "Non renseigné";
      } else if (
        this.carrier.specialities.length === 0 ||
        this.choices.specialities == null
      ) {
        return "Aucune";
      } else {
        return this.carrier.specialities
          .map((speciality) => this.choices.specialities[speciality])
          .join(", ");
      }
    },

    classExpirationDateLTI() {
      return getClassExpirationDate(this.carrier.lti_date_fin);
    },

    classExpirationDateLC() {
      return getClassExpirationDate(this.carrier.lc_date_fin);
    },

    licensesBeginInFuture() {
      const now = new Date();
      return (
        (this.carrier.lc_date_debut != null &&
          new Date(this.carrier.lc_date_debut) > now) ||
        (this.carrier.lti_date_debut != null &&
          new Date(this.carrier.lti_date_debut) > now)
      );
    },

    licensesAlmostExpired() {
      return (
        this.classExpirationDateLTI !== "" || this.classExpirationDateLC !== ""
      );
    },

    licenseLtiPdfUrl() {
      return this.getLicensePdfUrl("lti");
    },

    licenseLcPdfUrl() {
      return this.getLicensePdfUrl("lc");
    },

    ...mapState(["choices", "user"]),
  },

  methods: {
    getLicensePdfUrl(type) {
      return `${process.env.VUE_APP_API_URL}${api.getCarrierLicensePdfUrl(
        this.carrier.siret,
        type
      )}`;
    },

    licenseOnClick(type) {
      this.licensesLoading[type] = true;
      setTimeout(() => {
        this.licensesLoading[type] = false;
      }, 1500);
    },
  },
};
</script>
