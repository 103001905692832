<template lang="pug">
div
  v-row.adock-header(justify="center")
    v-col.pa-12.white--text(cols="12" sm="11" md="9" lg="8" xl="6")
      h1 Expéditeurs
      h2 Votre besoin de transport, à portée de clics
  div.adock-section.adock-section-grey
    v-container.pt-0
      v-row(no-gutters)
        v-col(offset="1" cols="10")
          h2.mb-6 Cherchez gratuitement parmi {{ stats.carrier && stats.carrier.count || '50 000' }} entreprises de transport en France
          v-card(ref="searchForm").adock-card
            v-alert.adock-show-linebreaks(
              v-if="errorMessage"
              type="error"
              outlined
              text
            ) {{ errorMessage }}
            v-container
              v-row(dense)
                v-col(cols="12")
                  v-text-field(
                    label="Nom, code postal ou SIREN de l'entreprise"
                    v-model.trim="searchForm.q"
                    @keyup.enter="search"
                    data-cy="searchFormQ"
                  )
              v-row(dense)
                v-col(cols="12" md="4")
                  v-row
                    v-col(cols="12")
                      v-checkbox.ma-0(
                        label="< 3,5 tonnes (léger)"
                        v-model="searchForm.licenseLTI"
                      )
                      v-checkbox.ma-0(
                        label="> 3,5 tonnes (lourd)"
                        v-model="searchForm.licenseLC"
                      )
                      v-checkbox.mt-2(
                        v-model="searchForm.isObjectifCO2"
                      )
                        template(v-slot:label="")
                          | Objectif CO
                          sub 2
                v-col(cols="12" md="8")
                  v-row(dense)
                    v-col(cols="12")
                      v-select.pt-0(
                        label="Spécialités"
                        v-model="searchForm.specialities"
                        :items="options.specialities"
                        chips
                        multiple
                        deletable-chips
                        return-object
                      )
                  v-row(dense)
                    v-col(cols="12" md="6")
                      v-autocomplete(
                        label="Département d'enlèvement"
                        v-model="searchForm.departementFrom"
                        :items="DEPARTEMENTS"
                        :item-text="departementItemText"
                        item-value="number"
                        @keyup.enter="search"
                        data-cy="searchFormDepartementFrom"
                        hint="Numéro ou nom du département, ex. 44 ou Loire-Atlantique"
                        clearable
                      )
                    v-col(cols="12" md="6")
                      v-autocomplete(
                        label="Département de livraison"
                        v-model="searchForm.departementTo"
                        :items="DEPARTEMENTS"
                        :item-text="departementItemText"
                        item-value="number"
                        @keyup.enter="search"
                        hint="Numéro ou nom du département, ex. Ille et Vilaine ou 35"
                        clearable
                      )
              v-row(dense)
                v-col(cols="12")
                  v-row(align="center" justify="end")
                    v-btn.mr-4(@click.native="clear") Effacer
                    v-btn.mr-3(
                      large
                      color="primary"
                      @click.native="search"
                    ) Chercher
          CarrierList(
            :searchParams="searchParams"
            :searchResponseIsEmpty="searchResponseIsEmpty"
            :carriers="carriers"
            :limit="limit"
          )
  div.adock-section.adock-section-dark-blue.white--text
    v-row.pt-6(justify="space-around")
      v-col(cols="12" md="3")
        h2.text-center
          v-icon(x-large color="white") mdi-alarm
        p
          h4.text-center Rapide
        p.text-center
          | Recherchez en quelques clics le partenaire correspondant à votre besoin de transport.
      v-col(cols="12" md="3")
        h2.text-center
          v-icon(x-large color="white") mdi-lock-open-variant-outline
        p
          h4.text-center Gratuit
        p.text-center
          | Consultez gratuitement les données de l’ensemble des établissements de transport de France.
      v-col(cols="12" md="3")
        h2.text-center
          v-icon(x-large color="white") mdi-clipboard-check
        p
          h4.text-center Fiable
        p.text-center
          | Accédez à des données certifiées par l’Etat et sécurisées par les transporteurs.
  div.adock-section
    h3.text-center Une relation transporteur - chargeur simple, directe et sécurisée
    v-row.mt-5(justify="space-around")
      v-col.text-center(cols="12" md="3")
        h2 100 %
        h3 des entreprises en France référencées
        p
          | Toute entreprise ou établissement de transport routier dispose d’une fiche présentant
          | les données issues du registre des transports et du répertoire Sirene.
      v-col.text-center(cols="12" md="3")
        h2 30
        h3 données déjà disponibles par fiche entreprise
        p
          | Contact, spécialités, aire de travail, licences, Objectif CO
          sub 2
          |  de l’ADEME...
          | Des données de l’État enrichies par les transporteurs.
      v-col.text-center(cols="12" md="3")
        h2 2
        h3 documents à consulter ou télécharger
        p
          | Attestations d’emploi ou de non emploi de travailleurs soumis à autorisation de travail
          | sont accessibles depuis les fiches entreprises.
  div.adock-section.adock-section-grey
    h3.text-center Ils parlent du service
    v-container(
      d-flex
      px-0
    )
      v-row
        v-col(
          cols="10" offset="1" md="6" offset-md="0"
          dense
        )
          v-card(height="100%")
            v-col(cols="10" offset="1")
              blockquote.blockquote
                v-icon mdi-format-quote-open
                | Le profil transporteur A Dock va devenir le « profil administratif » incontournable
                | pour toute contractualisation. Un énorme gain de temps.
                br
                br
                h4.text-sm-left Le journal des entreprises
        v-col(
          cols="10" offset="1" md="6" offset-md="0"
          dense
        )
          v-card(height="100%")
            v-col(cols="10" offset="1")
              blockquote.blockquote
                v-icon mdi-format-quote-open
                | A Dock vise différents objectifs : élargir le panel d’offres de transport aux
                | acteurs économiques, garantir un accès à des données fiabilisées, parce que
                | portées par l’Etat, simplifier les échanges administratifs et commerciaux
                | entre chargeurs et transporteurs.
                br
                br
                h4.text-sm-left Stratégies logistiques
  Contact
</template>

<script>
import deepClone from "lodash.clonedeep";
import { mapState } from "vuex";

import api from "@/api";
import Contact from "@/components/Contact.vue";
import CarrierList from "@/components/CarrierList.vue";
import { DEPARTEMENTS } from "@/departements";

const defaultSearchForm = {
  q: "",
  withLicenseLTI: false,
  withLicenseLC: false,
  departementFrom: "",
  departementTo: "",
  specialities: [],
  isObjectifCO2: false,
};

export default {
  // Required for keep-alive
  name: "ShipperSearch",

  props: {
    keepPreviousSearch: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isSearching: false,
      searchForm: deepClone(defaultSearchForm),
      searchParams: null,
      carriers: null,
      limit: 0,
      errorMessage: null,
    };
  },

  created() {
    this.DEPARTEMENTS = DEPARTEMENTS;
  },

  components: {
    CarrierList,
    Contact,
  },

  computed: {
    searchResponseIsEmpty() {
      return (
        !this.isSearching && this.carriers != null && this.carriers.length === 0
      );
    },
    ...mapState(["stats", "options"]),
  },

  methods: {
    departementItemText(item) {
      return `${item.number} - ${item.name}`;
    },

    async search() {
      // Remove error message as soon as the user clicks.
      this.errorMessage = null;
      this.isSearching = true;
      const params = {};

      // The parameters of the query are in French.
      // Only add not empty criteria.
      if (this.searchForm.q) {
        params["q"] = this.searchForm.q;
      }

      params["license-types"] = [];
      if (this.searchForm.licenseLTI) {
        params["license-types"].push("lti");
      }

      if (this.searchForm.licenseLC) {
        params["license-types"].push("lc");
      }

      if (this.searchForm.departementFrom) {
        params["departement-depart"] = this.searchForm.departementFrom;
      }

      if (this.searchForm.departementTo) {
        params["departement-arrivee"] = this.searchForm.departementTo;
      }

      if (this.searchForm.isObjectifCO2) {
        params["is-objectif-co2"] = true;
      }

      params["specialities"] = this.searchForm.specialities.map(
        (item) => item.value
      );

      const response = await api.get(api.searchCarriersUrl, params);
      if (response.status === 200) {
        // Disable reactivity to speed up rendering
        this.carriers = Object.freeze(response.data.carriers);
        this.limit = response.data.limit || 0;
        // Build an object with search parameters to display them to the user with the results
        this.searchParams = JSON.parse(JSON.stringify(this.searchForm));
      } else {
        if (response.data.message) {
          this.errorMessage = response.data.message;
        }
        this.carriers = null;
        this.limit = 0;
      }

      this.isSearching = false;

      this.$nextTick(() => {
        this.$vuetify.goTo(this.$refs.searchForm, {
          offset: 16,
        });
      });
    },

    clear() {
      this.searchForm = deepClone(defaultSearchForm);
      this.carriers = null;
    },
  },
};
</script>
