import "leaflet/dist/leaflet.css";
import "@mdi/font/css/materialdesignicons.css";

import Vue from "vue";

import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import { version } from "../package.json";
import VCurrencyField from "v-currency-field";
import { VTextField } from "vuetify/lib"; //Globally import VTextField

import vuetify from "@/plugins/vuetify";
import store from "@/store";
import router from "@/router";

import App from "@/App.vue";
import { loadTracker } from "@/tracker";
import "@/filters";

Vue.config.productionTip = false;

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_URL,
    release: version,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: ["localhost", "adock.beta.gouv.fr", /^\//],
      }),
    ],
  });

  // Piwik/Matomo tracker
  loadTracker();
}

Vue.component("v-text-field", VTextField);
Vue.use(VCurrencyField, {
  locale: "fr-FR",
  decimalLength: 0,
  autoDecimalMode: true,
  min: 0,
  max: null,
  defaultValue: 0,
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
