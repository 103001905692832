<template lang="pug">
  v-app
    Message
    v-navigation-drawer.hidden-print-only(
      app
      temporary
      v-model="isDrawerVisible"
    )
      v-list(dense)
        v-list-item(
          link
          :to="{name: 'carriers__welcome'}"
        )
          v-list-item-content
            v-list-item-title Transporteurs
        v-list-item(
          link
          :to="{name: 'shippers__search'}"
        )
          v-list-item-content
            v-list-item-title Expéditeurs
        v-list-item(
          link
          v-if="user && user.is_staff"
          :to="{name: 'selftests'}"
        )
          v-list-item-content
            v-list-item-title Self test
        template(
          v-if="user"
        )
          v-list-item(
            link
            :to="{name: 'accounts__dashboard'}"
          )
            v-list-item-content
              v-list-item-title Mon espace
          v-list-item(
            link
          )
            v-list-item-content(@click="logOutUser")
              v-list-item-title Se déconnecter
        v-list-item(
          link
          v-else
          :to="{name: 'accounts__login'}"
        )
          v-list-item-content
            v-list-item-title Connexion
    v-app-bar.hidden-print-only(
      app
      light
    )
      v-app-bar-nav-icon(
        v-if="$vuetify.breakpoint.smAndDown"
        @click.stop="isDrawerVisible = !isDrawerVisible"
      )
      v-toolbar-title.pt-1
        v-row.pl-2(align="center" no-gutters)
          v-col
            img(
              src="./assets/marianne.svg"
              height="50px"
            )
          v-col.pl-2
            router-link.adock-no-link(:to="{name: 'home'}")
              img(
                alt="adock.beta.gouv.fr"
                src="./assets/adock.beta.gouv.fr-50.png"
                height="25px"
              )
      v-spacer
      v-toolbar-items(v-if="!$vuetify.breakpoint.smAndDown")
        v-btn(
          :to="{name: 'carriers__welcome'}"
          text
        ) Transporteurs
        v-btn(
          :to="{name: 'shippers__search'}"
          text
        ) Expéditeurs
        v-btn(
          v-if="user && user.is_staff"
          :to="{name: 'selftests'}"
          text
        ) Self test
        template(v-if="user")
          v-btn(
            :to="{name: 'accounts__dashboard'}"
            text
          ) Mon espace
          v-btn(icon @click="logOutUser")
            v-icon mdi-exit-to-app
        v-btn(
          v-else
          :to="{name: 'accounts__login'}"
          text
        ) Connexion
    v-main
      v-alert.adock-alert-ie11(
        v-if="isIE11"
        type="warning"
        outlined
        text
      )
        | Nous vous recommandons d'utiliser un navigateur récent tel que&nbsp;
        a(href="https://www.mozilla.org/fr/firefox/") Firefox
        |  ou Chrome pour une expérience optimale.
      keep-alive(include="ShipperSearch")
        router-view
    v-footer.pl-4.pr-6.adock-footer.hidden-print-only(
      color="#26353f"
      height="auto"
    )
      v-row(align="center")
        v-col(
          cols="8"
          md="4"
          order="2"
          order-md="1"
        )
          v-row(justify="start")
            v-col
              img.pr-3.float-left.adock-footer-mtes(src="./assets/logo-mtes.svg")
              p.adock-footer-description
                | A Dock est un service numérique développé par la Direction générale des infrastructures,
                | des transports et de la mer, avec l’appui de la Fabrique numérique, incubateur du Ministère
                | de la Transition écologique et solidaire.
        v-col.px-6.text-center(
          cols="12"
          md="4"
          order="1"
          order-md="2"
        )
          router-link.adock-footer-link.pr-3(:to="{name: 'about'}") En savoir plus
          router-link.adock-footer-link.pr-3(:to="{name: 'cgu'}") CGU
          router-link.adock-footer-link(:to="{name: 'stats'}") Statistiques
        v-col(
          cols="4"
          md="4"
          order="3"
          order-md="3"
        )
          v-row(align="center" justify="end")
            div.pr-6
              a.adock-footer-la-fabrique-numerique
                img(src="./assets/logo-la-fabrique-numerique.svg")
            div
              a.adock-footer-french-mobility(href='https://www.ecologique-solidaire.gouv.fr/french-mobility')
                img(srcset='./assets/logo-french-mobility-120.png 1x, ./assets/logo-french-mobility-240.png 2x')
</template>

<style lang="scss">
$color-medium: #0053b3;
$color-darker: #00234d;
$color-light: #006be6;
$color-dark: #003b80;
$color-lighter-grey: #ebeff3;
$color-light-grey: #c9d3df;
$color-black: #1c1c1c;

@font-face {
  font-family: "Source Sans Pro";
  src: url("./assets/fonts/source-sans-pro/sourcesanspro-regular.otf");
  font-weight: 400;
  font-display: block;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("./assets/fonts/source-sans-pro/sourcesanspro-bold.otf");
  font-weight: 800;
  font-display: block;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("./assets/fonts/source-sans-pro/sourcesanspro-it.otf");
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: "Evolventa";
  src: url("./assets/fonts/evolventa/evolventa-bold.ttf");
  font-weight: 800;
  font-display: block;
}

@media print {
  body,
  html {
    font-size: 11px;
  }
  .v-main {
    padding: 0 !important;
  }
}

.v-application {
  font-family: "Source Sans Pro", Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  .title,
  .headline,
  .display-1,
  .display-2,
  .display-3,
  .display-4 {
    font-family: "Evolventa", "Trebuchet MS", sans-serif !important;
  }
}

.v-btn {
  letter-spacing: 0;
}

.adock-list-nav.v-list--nav {
  padding-left: 0;
  padding-right: 0;
}

.adock-list-nav .v-list-item--active {
  // dark-text
  background: linear-gradient(
    to right,
    $color-medium,
    $color-medium 5px,
    $color-lighter-grey 5px,
    $color-lighter-grey
  );
  color: $color-darker;
  border-radius: 0;
  opacity: unset;
}

.adock-list-nav .v-list-item__content {
  padding: 12px 8px 12px 20px;
}

div.v-stepper__step.text-center div.v-stepper__label {
  text-align: center;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Evolventa", "Trebuchet MS", sans-serif;
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
  margin-top: 0;
}

h1 {
  font-size: 2.125rem !important;
  font-weight: 400;
  line-height: 2.5rem;
  letter-spacing: 0.0073529412em !important;
}

h2 {
  font-size: 1.5rem !important;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: normal;
}

h3 {
  font-size: 1.25rem !important;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.0125em !important;
}

h4 {
  font-size: 1.125rem !important;
  font-weight: 400;
  line-height: 1.75rem;
  letter-spacing: 0.009375em !important;
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 0.8rem !important;
}

.adock-icon-black {
  color: rgba(0, 0, 0, 0.87) !important;
}

.adock-grey-text {
  color: rgba(0, 0, 0, 0.54);
}

.adock-alert-ie11 {
  margin: 0;
}

.adock-card-with-header {
  border-radius: 3px;
  border: 1px solid $color-light-grey !important;
  box-shadow: rgba(201, 211, 223, 0.2) 0px 1px 2px 0px;
}

.adock-card {
  @extend .adock-card-with-header;
  padding: 0.6em 1.5em;
}

.adock-carrier-list-item {
  white-space: unset;
}

.adock-carrier-list-details {
  width: 17ex;
}

.adock-date-expired {
  color: red;
}

.adock-date-almost-expired {
  color: orange;
}

.adock-help h4,
.adock-help h5,
.adock-help h6 {
  padding: 0.5em 0;
}

.adock-hero p {
  margin: 1em auto;
  font-size: 1.5em;
}

.adock-link {
  text-decoration: underline;
}

.adock-no-link {
  color: inherit !important;
  text-decoration: none !important;
}

.adock-header {
  height: 12em;
  background: no-repeat center url("assets/header-background.jpg");
  background-size: cover;
}

.adock-section {
  padding: 3em 0;
}

.adock-section-grey {
  background-color: $color-lighter-grey;
}

.adock-section-dark-blue {
  background-image: linear-gradient(to left, #0053b3, #003b80);
}

.adock-section-white {
  background-color: white;
}

.adock-footer-link {
  color: white !important;
  text-decoration: none;
  font-size: 16px;
}

.adock-footer-la-fabrique-numerique img {
  height: 60px;
  max-height: 60px;
}

.adock-footer-mtes {
  max-height: 120px;
}

.adock-footer-description {
  padding: 0 2em;
  color: white;
  font-size: 13px;
  text-align: justify;
}

.adock-show-linebreaks {
  white-space: pre-wrap;
}

.adock-no-wrap {
  overflow-x: auto;
  overflow-y: hidden;
  flex-wrap: nowrap;
}

.adock-stats-title {
  @extend h1;
  color: $color-light;
}

.adock-stats-counter {
  @extend h3;
  color: $color-light;
}

.adock-stats-subtitle {
  @extend h4;
  color: black !important;
}

.adock-stats-description {
  text-justify: inter-word;
}
</style>

<script>
import auth from "@/auth";

import { displayUserMixin } from "@/mixins";
import Message from "@/components/Message.vue";

export default {
  mixins: [displayUserMixin],

  data() {
    return {
      isDrawerVisible: false,
      isIE11: false,
    };
  },

  components: {
    Message,
  },

  created() {
    this.$store.dispatch("loadMeta");
    this.isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
    if (auth.getToken()) {
      this.$store.dispatch("loadUserProfile", { withErrorHandling: false });
    }
  },

  methods: {
    logOutUser() {
      this.$store.dispatch("logOutUser");
      this.$router.push({ path: "/" });
    },
  },
};
</script>
