import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"adock-card mt-2"},[_c(VCardTitle,{attrs:{"primary-title":""}},[_c('h2',[_vm._v("Nouveau message")])]),_c(VCardText,[_c(VForm,{ref:"form",on:{"submit":_vm.submit}},[(_vm.errorMessage)?_c(VAlert,{staticClass:"adock-show-linebreaks",attrs:{"type":"error","outlined":"","text":""}},[_vm._v(_vm._s(_vm.errorMessage))]):_vm._e(),(_vm.user)?_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_vm._v("À : "+_vm._s(this.user.full_name))])],1):_vm._e(),_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c(VTextField,{attrs:{"label":"Sujet *","error-messages":_vm.fieldErrors.title},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})],1)],1),_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12","md":"8"}},[_c(VTextarea,{attrs:{"placeholder":"Votre message *","error-messages":_vm.fieldErrors.content},model:{value:(_vm.form.content),callback:function ($$v) {_vm.$set(_vm.form, "content", $$v)},expression:"form.content"}})],1)],1),_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VBtn,{attrs:{"color":"primary","type":"submit"}},[_vm._v("Envoyer")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }