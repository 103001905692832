import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VForm,{on:{"submit":_vm.submit}},[(_vm.errorMessage)?_c(VAlert,{staticClass:"adock-show-linebreaks",attrs:{"outlined":"","text":""}},[_vm._v(_vm._s(_vm.errorMessage))]):_vm._e(),(!_vm.user.has_accepted_cgu)?_c(VCheckbox,{attrs:{"error-messages":_vm.fieldErrors.has_accepted_cgu,"data-cy":"inputHasAcceptedCGU","hide-details":""},on:{"change":function($event){_vm.fieldErrors.has_accepted_cgu = null}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Je certifie avoir lu et accepté les "),_c('router-link',{attrs:{"to":{name: 'cgu'}}},[_vm._v("Conditions générales d'utilisation")]),_vm._v("*")],1)]},proxy:true}],null,false,2541185935),model:{value:(_vm.hasAcceptedCGU),callback:function ($$v) {_vm.hasAcceptedCGU=$$v},expression:"hasAcceptedCGU"}}):_vm._e(),(!_vm.user.has_accepted_cgu)?_c(VCheckbox,{attrs:{"label":"Je certifie être habilité(e) à réaliser les démarches au nom de l'entreprise *","error-messages":_vm.fieldErrors.has_accepted_cgu,"data-cy":"inputIsEntitled","hide-details":""},model:{value:(_vm.isEntitled),callback:function ($$v) {_vm.isEntitled=$$v},expression:"isEntitled"}}):_vm._e(),_c(VCheckbox,{attrs:{"label":"J'accepte d'être informé(e) des nouveautés et actualités d'A Dock","hide-details":""},on:{"change":function($event){_vm.fieldErrors.has_subscribed_newsletter = null}},model:{value:(_vm.hasSubscribedNewsletter),callback:function ($$v) {_vm.hasSubscribedNewsletter=$$v},expression:"hasSubscribedNewsletter"}}),(!_vm.user.has_accepted_cgu)?_c('p',{staticClass:"pt-6 caption"},[_vm._v("* champs obligatoires")]):_vm._e(),_c(VCol,[_c(VRow,[_vm._t("default",function(){return [_c(VBtn,{attrs:{"disabled":_vm.isDisabled,"type":"submit"},on:{"click":_vm.submit}},[_vm._v("Accepter")])]},{"submit":_vm.submit,"isDisabled":_vm.isDisabled})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }