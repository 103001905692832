<template lang="pug">
div.fill-height.adock-section-grey
  CarrierHeader
  div.adock-section
    v-container.pt-0
      v-stepper.adock-card-with-header(
        v-model="step"
        alt-labels
      )
        v-stepper-header
          v-stepper-step.text-center(
            step="1"
            :complete="step > 1"
          ) Créez votre compte
          v-divider
          v-stepper-step.text-center(
            :step="2"
            :complete="step > 2"
          ) Renseignez votre situation
          v-divider
          v-stepper-step.text-center(
            step="3"
            :complete="step > 3"
          ) Finalisez votre inscription
        v-stepper-items
          v-stepper-content(
            step="1"
          )
            v-row
              v-col(offset-md="1" md="10")
                v-container
                  p.title 1. Créez votre compte
                  template(v-if="!user")
                    h5 en vous identifiant via FranceConnect
                    v-row.pt-6(dense)
                      v-col.text-center
                        v-btn.adock-france-connect(
                          @click="goToFranceConnectAuthorizeUrl"
                        )
                        p
                          a(
                            href="https://app.franceconnect.gouv.fr/en-savoir-plus"
                          ) Qu’est-ce que FranceConnect ?
                    v-row(dense)
                      v-col
                        h5 ou en renseignant votre identité
                    v-row(dense)
                      v-col
                        AccountCreateForm(
                          @account-created="nextStepFromAccountCreated($event)"
                        )
                          template(v-slot:submit-button="slotProps")
                            v-btn(
                              color="primary"
                              @click="slotProps.submit"
                              :disabled="slotProps.isDisabled"
                              type="submit"
                            ) Suivant
                  template(v-else)
                    h5 Vous êtes identifié(e) via {{ user.provider_display }}
                    v-row(dense)
                      v-col.text-center
                        p.pt-6
                          b {{ user.first_name }} {{ user.last_name }}
                          br
                          | {{ user.email }}
                    p si vous souhaitez créer un autre compte, veuillez vous déconnecter.
                    AccountCGUNewsletter(
                      v-slot="slotProps"
                      :user="user"
                      :withNotificationMessages="false"
                      @account-cgu-accepted="nextStepFromAccountCreated($event)"
                    )
                      v-row(dense)
                        v-col(align="right")
                          v-btn(
                            color="primary"
                            @click="slotProps.submit"
                            :disabled="slotProps.isDisabled"
                            type="submit"
                          ) Suivant
          v-stepper-content(
            step="2"
          )
            v-row
              v-col(offset-md="1" md="10")
                v-container
                  p.title 2. Votre situation
                  p Vous êtes déjà inscrit au registre du commerce et des sociétés (RCS) ?
                  v-radio-group(
                    v-model="isCreated"
                    :mandatory="false"
                  )
                    v-radio(
                      label="Oui"
                      :value="true"
                    )
                    v-radio(
                      label="Non. Je souhaite créer une entreprise de transport.",
                      :value="false"
                    )
                  template(v-if="isCreated")
                    p Votre entreprise est-elle inscrite au registre national des transports routiers ?
                    v-radio-group(
                      v-model="isRegistered"
                      :mandatory="false"
                    )
                      v-radio(
                        label="Oui"
                        :value="true"
                      )
                      v-radio(
                        label="Non. Je souhaite faire une demande d’autorisation d’exercer la profession de transporteur public routier de marchandises, de déménagement et/ou loueur de véhicules industriels avec conducteur.",
                        :value="false"
                      )
                  template(v-if="isCreated == false")
                    p Veuillez contacter votre DREAL pour effectuer une demande d'inscription.
                  CarrierSearch.mb-2(
                    v-if="isSearchable"
                    :is-registered="isRegistered"
                    :key="carrierSearchResetKey"
                    :canEdit="true"
                    @carrier-selected="getSelectedCarrier($event)"
                  )
                  v-row(dense)
                    v-col(align="right")
                      v-btn(
                        color="primary"
                        :disabled="isCreated === false || (!carrier || carrier.user_can_edit === false)"
                        @click="nextStepFromCarrierSelected"
                      ) Suivant
          v-stepper-content(
            step="3"
          )
            v-row(v-if="carrier")
              v-col(cols="12" offset-md="1" md="10")
                v-container
                  p.title 3. Finalisez votre inscription {{ carrier.enseigne }}
                  p
                    | Avant de terminer votre inscription, vous devez mettre à jour les informations ci-dessous.
                    br
                    | Elles seront publiées et visibles sur votre fiche entreprise.
                  CarrierEditForm(
                    :carrier="carrier"
                    @carrier-saved="nextStepFromCarrierSaved($event)"
                    :extend-payload="extendCarrierPayload"
                  )
                    template(v-slot:cancel-button="")
                      v-btn.mr-4(
                        @click="step = 2"
                      ) Précédent
                    template(v-slot:submit-button="slotProps")
                      v-btn(
                        color="primary"
                        @click.native="slotProps.submit"
                      ) Terminer
</template>

<script>
/*
The user can create an account on first step, the message of account creation is
stored to be display on confirmation screen (after step 3). If the confirmation
of carrier email is required that will be displayed after the account
confirmation.

When the user is already logged on first step, the confirmation (after step 3)
will be dedicated to carrier's email (if changed and different of user's
account).
*/
import { mapState } from "vuex";
import CarrierHeader from "@/components/CarrierHeader.vue";

import api from "@/api";

import {
  URL_TRANSPORTEURS__INSCRIPTION,
  getFranceConnectAuthorizeUrl,
} from "@/router";

import AccountCreateForm from "@/components/AccountCreateForm.vue";
import AccountCGUNewsletter from "@/components/AccountCGUNewsletter.vue";
import CarrierEditForm from "@/components/CarrierEditForm.vue";
import CarrierSearch from "@/components/CarrierSearch.vue";

export default {
  props: {
    carrierSiret: {
      type: String,
      required: false,
      default: null,
    },
  },

  components: {
    AccountCreateForm,
    AccountCGUNewsletter,
    CarrierEditForm,
    CarrierHeader,
    CarrierSearch,
  },

  data() {
    return {
      step: 1,
      carrier: null,
      account: null,
      carrierSearchResetKey: 0,
      isRegistered: null,
      isCreated: null,
    };
  },

  created() {
    this.accountConfirmation = null;
  },

  watch: {
    carrier: function (newValue) {
      if (newValue === null) {
        // Reset subcomponent on carrier reset
        this.carrierSearchResetKey += 1;
      }
    },

    step: async function (newValue) {
      // Next step if carrier already selected in URL
      if (newValue === 2 && this.carrierSiret) {
        this.carrier = { siret: this.carrierSiret };
        await this.nextStepFromCarrierSelected();
      }
    },
  },

  computed: {
    isSearchable() {
      // We want explicit answers before displaying the search input
      return (
        (this.isCreated === true && this.isRegistered === true) ||
        (this.isCreated === true && this.isRegistered === false)
      );
    },
    ...mapState(["user"]),
  },

  methods: {
    goToTop() {
      this.$vuetify.goTo(".v-stepper__content", {
        offset: 0,
      });
    },

    goToFranceConnectAuthorizeUrl() {
      const nextUrl = `/transporteur/inscription`;
      window.location = getFranceConnectAuthorizeUrl(this.$route, nextUrl);
    },

    getSelectedCarrier(data) {
      this.carrier = data;
    },

    extendCarrierPayload(payload) {
      // If the user is authenticated, it will be ignored by the server.
      // If provided, it's only allowed for not confirmed users (checked by server).
      payload.created_by_email = this.account.email;
    },

    // From step 1 -> 2
    nextStepFromAccountCreated(data) {
      this.account = data.account;
      this.accountConfirmation = data.confirmation ? data.confirmation : null;
      this.step = 2;
      this.goToTop();
    },

    // From step 2 -> 3
    async nextStepFromCarrierSelected() {
      if (
        this.isRegistered ||
        (this.carrier?.siret && !this.carrier?.raison_sociale)
      ) {
        // In "registre de transport de marchandises"
        const url = api.getCarrierUrl(this.carrier.siret);
        const response = await api.get(url);
        this.goToTop();
        if (response.status === 200) {
          this.carrier = response.data.carrier;
          this.step = 3;
        } else {
          this.$router.push({
            name: "error",
            params: {
              message: `Impossible de trouver l'entreprise ${this.carrier.siret}`,
              status: response.status,
              fallbackUrl: URL_TRANSPORTEURS__INSCRIPTION,
            },
          });
        }
      }
    },

    // From step 3 -> End
    nextStepFromCarrierSaved(data) {
      const confirmation =
        this.accountConfirmation || data.confirmation || null;
      if (confirmation) {
        this.$router.push({
          name: "confirmations__waiting",
          params: {
            message: confirmation.message,
          },
        });
      } else {
        this.$store.commit("MESSAGE_ADD", data);
        this.$router.push({ name: "accounts__dashboard" });
      }
    },
  },
};
</script>
