<template lang="pug">
div.adock-section-grey
  v-container(fluid)
    v-row(justify="center" wrap)
      v-col(cols="12" sm="11" md="9" lg="8" xl="6")
        router-link.adock-no-link(
          v-if="fromSearch"
          :to="{name: 'shippers__search', params: {keepPreviousSearch: true}}"
        )
          v-btn(icon)
            v-icon mdi-chevron-left
          span.subtitle-1.text-no-wrap Retour aux résultats
        v-card.adock-card.pa-0
          CarrierCardHeader(
            :carrier="carrier"
            :detail-url="detailUrl"
            :other-facilities="carrier.other_facilities"
          )
        v-alert(
          :value="!!carrier.deleted_at"
          type="warning"
        )
          | Cet établissement est absent du registre transports de marchandises
          | depuis le {{ carrier.deleted_at | asLocaleDate }}. Cela peut faire
          | suite à l'expiration de la licence de transport.
        v-alert(
          :value="!carrier.sirene_exists"
          type="warning"
        )
          | Cet établissement n'est pas publiée dans la base Sirene de l'Insee.
          | La cause peut être l'opposition au démarchage commercial.
          | Nous vous invitons à contacter le
          |
          a(href="https://www.service-public.fr/professionnels-entreprises/vosdroits/F24023") Centre de formalités des entreprises
          |
          | dont vous dépendez.
        v-alert(
          :value="!!carrier.sirene_closed_at"
          type="warning"
        )
          | Cet établissement est fermé depuis le {{ carrier.sirene_closed_at | asLocaleDate }}.
          | L'information provient de la base Sirene.
        h4.mt-2.mb-2 Coordonnées
        v-card.adock-card
          v-container
            v-row
              v-col(cols="4" offset-md="1" md="5") Téléphone
              v-col(align="right" cols="8" md="5")
                a(
                  v-if="carrier.telephone"
                  :href="'tel:' + carrier.telephone"
                ) {{ carrier.telephone }}
                span(v-else) Non renseigné
            v-row
              v-col(cols="4" offset-md="1" md="5") Adresse e-mail
              v-col(align="right" cols="8" md="5")
                a(
                  v-if="carrier.email"
                  :href="'mailto:' + carrier.email"
                ) {{ carrier.email }}
                span(v-else) Non renseigné
            v-row
              v-col(cols="4" offset-md="1" md="5") Site Web
              v-col(align="right" cols="8" md="5")
                a(
                  v-if="carrier.website"
                  :href="carrier.website"
                ) {{ carrier.website }}
                span(v-else) Non renseigné
            v-row
              v-col(cols="4" offset-md="1" md="5") Adresse
              v-col(align="right" cols="8" md="5")
                | {{ carrier.adresse }}
                br
                | {{ carrier.code_postal }} {{ carrier.ville }}
            // v-show is used to be sure #map is always here
            v-row(v-show="carrier.latitude")
              v-col(cols="12" offset-md="1" md="10")
                div#map
        h4.mt-2.mb-2 Activité
        v-card.adock-card
          v-container
            v-row
              v-col(cols="5" offset-md="1" md="5") Aire de travail
              v-col(align="right" cols="7" md="5") {{ choices.workingAreas[carrier.working_area] }}
            v-row(v-if="carrier.working_area === 'DEPARTEMENT' || carrier.working_area === 'REGION'")
              v-col(cols="5" offset-md="1" md="5") Départements livrés
              v-col(align="right" cols="7" md="5") {{ carrier.working_area_departements | asJoinedString }}
            v-row
              v-col(cols="4" offset-md="1" md="5") Spécialités
              v-col(align="right" cols="8" md="5") {{ displaySpecialities }}
            v-row(v-if="carrier.description")
              v-col(cols="4" offset-md="1" md="5") Description de l'activité
              v-col(align="right" cols="8" md="5") {{ carrier.description }}
        h4.mt-2.mb-2 Licences de transport
        v-card.adock-card
          v-container
            template(v-if="carrier.lti_numero")
              v-row(dense)
                v-col(cols="6" offset-md="1" md="5") Licence LTI (léger)
                v-col(align="right" cols="6" md="5") {{ carrier.lti_numero }}
              v-row(dense)
                v-col(cols="4" offset-md="1" md="5") validité
                v-col(align="right" cols="8" md="5")
                  span {{ carrier.lti_date_debut | asLocaleDate }} au
                  span(:class="classExpirationDateLTI")  {{ carrier.lti_date_fin | asLocaleDate }}
              v-row(dense)
                v-col(cols="4" offset-md="1" md="5") nombre
                v-col(align="right" cols="8" md="5") {{ carrier.lti_nombre }}
            template(v-if="carrier.lc_numero")
              br
              v-row(dense)
                v-col(cols="6" offset-md="1" md="5") Licence LC (lourd, + 3,5 tonnes)
                v-col(align="right" cols="6" md="5") {{ carrier.lc_numero }}
              v-row(dense)
                v-col(cols="4" offset-md="1" md="5") validité
                v-col(align="right" cols="8" md="5")
                  span {{ carrier.lc_date_debut | asLocaleDate }} au
                  span(:class="classExpirationDateLC")  {{ carrier.lc_date_fin | asLocaleDate }}
              v-row(dense)
                v-col(cols="4" offset-md="1" md="5") nombre
                v-col(align="right" cols="8" md="5") {{ carrier.lc_nombre }}
            template(v-if="carrier.objectif_co2")
              br
              v-row
                v-col(cols="6" offset-md="1" md="5")
                  | Objectif CO
                  sub 2
                v-col(align="right" cols="6" md="5") {{ choices.objectifCO2[carrier.objectif_co2] }}
              v-row
                v-col(cols="4" offset-md="1" md="5") période
                v-col(align="right" cols="8" md="5") {{ carrier.objectif_co2_begin | asLocaleDate }} au {{ carrier.objectif_co2_end | asLocaleDate }}
        h4.mt-2.mb-2 Documents
        v-card.adock-card
          v-container
            v-row(v-if="carrier.latest_certificate")
              v-col(cols="5" offset-md="1" md="6")
                | {{ carrier.latest_certificate.kind_display }}
                br
                span.adock-grey-text {{ carrier.latest_certificate.created_at | asLocaleDate }}
              v-col(align="right" cols="7" md="4")
                v-btn(
                  large
                  :href="API_URL + carrier.latest_certificate.pdf_url"
                ) Télécharger
                  v-icon(right) mdi-package-down
            v-row(v-else)
              v-col(offset-md="1" md="11")
                span.adock-grey-text Aucun document
        h4.mt-2.mb-2 Entreprise
        v-card.adock-card
          v-container
            v-row(v-if="carrier.debut_activite")
              v-col(cols="4" offset-md="1" md="5") Début d'activité
              v-col(align="right" cols="8" md="5") {{ carrier.debut_activite | asLocaleDate }}
            v-row
              v-col(cols="4" offset-md="1" md="5") Gestionnaire
              v-col(align="right" cols="8" md="5") {{ carrier.gestionnaire }}
            v-row
              v-col(cols="4" offset-md="1" md="5") SIRET
              v-col(align="right" cols="8" md="5") {{ carrier.siret }}
            v-row
              v-col(cols="4" offset-md="1" md="5") N° TVA
              v-col(align="right" cols="8" md="5") {{ carrier.numero_tva }}
            v-row
              v-col(cols="4" offset-md="1" md="5") Raison sociale
              v-col(align="right" cols="8" md="5") {{ carrier.raison_sociale }}
        h4.mt-2.mb-2(id="facilities") Autres établissements de l'entreprise
        v-card.adock-card
          v-container
            v-row
              v-col(
                v-if="carrier.other_facilities && carrier.other_facilities.length > 0"
              )
                v-data-table(
                  :headers="facilitiesHeaders"
                  :items="carrier.other_facilities"
                  hide-default-footer
                  class="elevation-1"
                  must-sort
                )
                  template(
                    slot="item"
                    slot-scope="props"
                  )
                    tr(:class="{'adock-tr-deleted': props.item.deleted_at || props.item.sirene_closed_at }")
                      td
                        router-link(:to="{name: 'carriers__detail', params: {carrierSiret: props.item.siret}}") {{ props.item.siret }}
                        span &nbsp;
                        v-tooltip(top)
                          template(v-slot:activator="{ on }")
                            v-icon(v-if="props.item.is_siege" small v-on="on") mdi-domain
                          div
                            span Siège social
                      td {{ props.item.code_postal }}
                      td {{ props.item.ville }}
                      td {{ props.item.debut_activite | asLocaleDate }}
              v-col(v-else cols="10" offset-md="1")
                span.adock-grey-text Aucun autre établissement

</template>

<style lang="scss">
.adock-tr-deleted {
  color: grey;
}

a[href^="tel:"],
a[href^="mailto:"] {
  text-decoration: none;
}

a[href^="tel:"]:before {
  content: "\260e";
  margin-right: 0.5em;
}

#map {
  width: 100%;
  height: 256px;
  text-shadow: 0 0 2px #fff;
  border: 1px solid #ccc;
  z-index: 1;
}
</style>

<script>
// Lazy loaded in created()
let L = null;
import * as Sentry from "@sentry/vue";
import { mapState } from "vuex";

import { routeLoadCarrierMixin } from "@/mixins";
import CarrierCardHeader from "@/components/CarrierCardHeader.vue";
import { getClassExpirationDate } from "@/carriers";

function setMarkerIcons(leaflet) {
  // Workaround to load marker icons
  delete leaflet.Icon.Default.prototype._getIconUrl;
  leaflet.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
  });
}

function getTileProvider() {
  return {
    url: "https://cartodb-basemaps-{s}.global.ssl.fastly.net/rastertiles/voyager/{z}/{x}/{y}.png",
    attribution:
      '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>' +
      ', &copy; <a href="https://carto.com/attribution">CARTO</a>',
  };
}

export default {
  mixins: [routeLoadCarrierMixin],

  props: {
    carrier: {
      type: Object,
      required: true,
    },
    fromSearch: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  components: {
    CarrierCardHeader,
  },

  data() {
    return {
      facilitiesHeaders: [
        {
          text: "SIRET",
          value: "siret",
        },
        {
          text: "Code postal",
          value: "code_postal",
        },
        {
          text: "Ville",
          value: "ville",
        },
        {
          text: "Début d'activité",
          value: "debut_activite",
        },
      ],
    };
  },

  created() {
    this.API_URL = process.env.VUE_APP_API_URL;
    this.map = null;
    import("leaflet")
      .then((leaflet) => {
        setMarkerIcons(leaflet.default);
        // Set the global L
        L = leaflet.default;
        if (this.map === null) {
          // Ensure not already rendered by mounted (unlikely)
          this.renderMap();
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
      });
  },

  mounted() {
    this.renderMap();
  },

  beforeUpdate() {
    if (this.map) {
      this.map.remove();
      this.map = null;
    }
  },

  updated() {
    this.$nextTick(() => {
      this.renderMap();
    });
  },

  computed: {
    detailUrl() {
      return `https://${process.env.VUE_APP_HOSTNAME}${this.$router.currentRoute.path}`;
    },

    displaySpecialities() {
      if (this.carrier.specialities == null) {
        return "Non renseigné";
      } else if (
        this.carrier.specialities.length === 0 ||
        this.choices.specialities == null
      ) {
        return "Aucune";
      } else {
        return this.carrier.specialities
          .map((speciality) => this.choices.specialities[speciality])
          .join(", ");
      }
    },

    classExpirationDateLTI() {
      return getClassExpirationDate(this.carrier.lti_date_fin);
    },

    classExpirationDateLC() {
      return getClassExpirationDate(this.carrier.lc_date_fin);
    },

    licensesAlmostExpired() {
      return (
        this.classExpirationDateLTI !== "" || this.classExpirationDateLC !== ""
      );
    },

    ...mapState(["choices", "user"]),
  },

  methods: {
    renderMap() {
      if (L == null) {
        // Module not loaded yet
        return;
      }

      if (this.carrier.latitude) {
        const center = [this.carrier.latitude, this.carrier.longitude];
        this.map = L.map("map", {
          center,
          zoom: 9,
          scrollWheelZoom: false,
        });

        const tileProvider = getTileProvider();
        const tileLayer = L.tileLayer(tileProvider.url, {
          minZoom: 0,
          maxZoom: 18,
          tileSize: 256,
          detectRetina: true,
          attribution: tileProvider.attribution,
        });
        tileLayer.addTo(this.map);
        L.marker(center).addTo(this.map);
      }
    },
  },
};
</script>
