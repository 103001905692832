import api from "@/api";

export const CONFIRMATION_ACCOUNT = "compte";
export const CONFIRMATION_EDITABLE = "changement";

export const CONFIRMATIONS = {
  [CONFIRMATION_ACCOUNT]: {
    message_in_progress: "Confirmation du nouveau compte en cours...",
    getUrl() {
      return api.getAccountConfirmUrl(this.relationId, this.relationToken);
    },
  },
  [CONFIRMATION_EDITABLE]: {
    message_in_progress:
      "Confirmation des modifications de la fiche entreprise en cours...",
    getUrl() {
      return api.getCarrierEditableConfirmUrl(
        this.relationId,
        this.relationToken
      );
    },
  },
};
