<template lang="pug">
  div
    v-card.adock-card.mt-1(v-if="searchResponseIsEmpty")
      v-card-text La recherche avec {{ searchParamsForDisplay }} n'a retourné aucun résultat.
    v-card.adock-card.mt-1(v-if="carriers && carriers.length > 0")
      v-list(two-line)
        v-subheader(v-if="limit") Seules les {{ carriers.length }} premières entreprises de la recherche {{ searchParamsForDisplay }} sont affichées.
        v-subheader(v-else) {{ carriers.length }} entreprise{{ carriers.length > 1 ? "s" : "" }} pour la recherche {{ searchParamsForDisplay }}
        template(v-for="(carrier, index) in carriers")
          v-divider(v-if="index !== 0", :key="'d-' + carrier.siret")
          v-list-item(
            :key="carrier.siret",
            :to="{ name: 'carriers__detail', params: { carrierSiret: carrier.siret, fromSearch: true }}")
            v-list-item-content
              v-list-item-title.adock-carrier-list-item {{ carrier.enseigne }}
              v-list-item-subtitle.adock-carrier-list-item
                | {{ carrier.code_postal }} {{ carrier.ville }}
            v-list-item-action
              v-list-item-action-text.adock-carrier-list-details
                span  &lt; 3,5 tonnes&nbsp;: {{ carrier.lti_nombre }}
                br
                span &gt; 3,5 tonnes&nbsp;: {{ carrier.lc_nombre }}
                br
                span {{ choices.workingAreas[carrier.working_area] }}
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    searchResponseIsEmpty: {
      type: Boolean,
      required: true,
    },
    searchParams: {
      type: Object,
      default: null,
    },
    // Array or null
    carriers: {
      required: true,
    },
    limit: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    searchParamsForDisplay() {
      const messages = [];

      if (this.searchParams === null) {
        return "";
      }

      if (this.searchParams.q !== "") {
        messages.push(`« ${this.searchParams.q} »`);
      }

      if (
        this.searchParams.licenseTypes &&
        this.searchParams.licenseTypes.length > 0
      ) {
        const licenseTypes = this.searchParams.licenseTypes.map(
          (item) => item.text
        );
        messages.push(`poids « ${licenseTypes.join(", ")} »`);
      }

      if (
        this.searchParams.departementFrom != null &&
        this.searchParams.departementFrom !== ""
      ) {
        messages.push(`enlèvement « ${this.searchParams.departementFrom} »`);
      }

      if (
        this.searchParams.departementTo != null &&
        this.searchParams.departementTo !== ""
      ) {
        messages.push(`livraison « ${this.searchParams.departementTo} »`);
      }

      if (
        this.searchParams.specialities &&
        this.searchParams.specialities.length > 0
      ) {
        const specialities = this.searchParams.specialities.map(
          (item) => item.text
        );
        messages.push(`spécialités « ${specialities.join(", ")} »`);
      }

      return messages.join(", ");
    },
    ...mapState(["choices"]),
  },
};
</script>
