export function getClassExpirationDate(endDateValue) {
  if (endDateValue == null) {
    return "";
  }

  const THREE_MONTHS_IN_S = 3 * 31 * 24 * 3600;
  const currentDate = new Date();
  const endDate = new Date(endDateValue);

  const difference = (endDate.valueOf() - currentDate.valueOf()) / 1000;
  if (difference < 0) {
    return "adock-date-expired";
  } else if (difference < THREE_MONTHS_IN_S) {
    return "adock-date-almost-expired";
  }

  return "";
}
