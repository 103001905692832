import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTimeline } from 'vuetify/lib/components/VTimeline';
import { VTimelineItem } from 'vuetify/lib/components/VTimeline';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.thread)?_c(VCard,{staticClass:"adock-card mt-2"},[_c(VCardTitle,{attrs:{"primary-title":""}},[_c('h2',[_vm._v(_vm._s(_vm.thread.title))])]),_c(VCardText,[_c('p',[_vm._v(_vm._s(_vm._f("asLocaleDate")(_vm.thread.created_at))+" - "+_vm._s(_vm.isClosed ? 'Traité' : 'En cours'))])]),_c(VContainer,[_c(VTimeline,{attrs:{"dense":_vm.$vuetify.breakpoint.smAndDown}},_vm._l((_vm.thread.messages),function(message,index){return _c(VTimelineItem,{key:message.id,attrs:{"color":_vm.getMessageColor(index),"right":_vm.fromMe(message),"left":!_vm.fromMe(message)},scopedSlots:_vm._u([{key:"opposite",fn:function(){return [_c('span',{staticClass:"title font-weight-bold"},[_vm._v(_vm._s(_vm._f("asLocaleDate")(message.created_at)))])]},proxy:true}],null,true)},[_c(VCard,{staticClass:"elevation-2"},[_c(VCardTitle,{staticClass:"title"},[_vm._v(_vm._s(message.from_user.full_name))]),_c(VCardSubtitle,[_vm._v(_vm._s(message.from_user.email))]),_c(VCardText,{staticClass:"adock-show-linebreaks"},[_vm._v(_vm._s(message.content))])],1)],1)}),1),(!_vm.isClosed)?_c(VForm,{ref:"form",on:{"submit":_vm.submit}},[_c(VRow,{attrs:{"dense":"","justify":"end"}},[_c(VCol,{attrs:{"cols":"12","md":"8"}},[_c(VTextarea,{attrs:{"placeholder":"Votre message *","error-messages":_vm.fieldErrors.content},model:{value:(_vm.form.content),callback:function ($$v) {_vm.$set(_vm.form, "content", $$v)},expression:"form.content"}})],1)],1),_c(VRow,{attrs:{"dense":"","justify":"end"}},[_c(VCol,{attrs:{"align":"right"}},[_c(VBtn,{staticClass:"mr-5",attrs:{"color":"primary","type":"submit"}},[_vm._v("Envoyer")]),_c(VBtn,{attrs:{"color":"success"},on:{"click":_vm.closeThread}},[_vm._v("Clore la discussion")])],1)],1)],1):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }