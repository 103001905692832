<template lang="pug">
  v-card.adock-card.pa-0
    v-form(
        autocomplete="nope"
        @submit="sign"
    )
      v-container(
        ref="mainContent"
      )
        v-alert.adock-show-linebreaks(
          v-if="errorMessage"
          type="error"
          outlined
          text
        ) {{ errorMessage }}
        v-row
          v-col(offset-md="1")
            h3 Générer une attestation de non emploi ou d'emploi
            span.caption Tous les champs sont obligatoires.
        v-row
          v-col(cols="12" offset-md="1" md="10")
            h4 Choisissez le type d'attestation :
            v-radio-group(
              v-model="kind"
              :error-messages="fieldErrors.kind"
            )
              v-radio(
                v-for="certificate in options.certificates"
                :key="certificate.value"
                :label="certificate.text"
                :value="certificate.value"
              )
        template(
          v-if="isCertificateWithworkers"
        )
          v-row(dense)
            v-col(cols="12" offset-md="1" md="10")
              h4 Salariés étrangers :
          v-row(
            dense
            v-for="(worker, index) in workers"
            :key="index"
          )
            v-col(cols="2" md="1" align-self="center" align="right")
              v-chip(outlined) {{ index + 1 }}
            v-col(cols="6" md="3")
              v-text-field(
                label="Nom et prénom"
                v-model="worker.name"
                hint="Nom et prénom du salarié"
                :error-messages="fieldErrors.workers && fieldErrors.workers[index] && fieldErrors.workers[index].name"
              )
            v-col(cols="4" md="2")
              v-text-field(
                label="Date"
                v-model="worker.date"
                hint="Date d'embauche"
                :error-messages="fieldErrors.workers && fieldErrors.workers[index] && fieldErrors.workers[index].date"
              )
            v-col(offset="2" cols="4" offset-md="0" md="2")
              v-text-field(
                label="Nationalité"
                v-model="worker.nationality"
                :error-messages="fieldErrors.workers && fieldErrors.workers[index] && fieldErrors.workers[index].nationality"
              )
            v-col(cols="4" md="3")
              v-text-field(
                label="N° d'autorisation"
                v-model="worker.work_permit"
                hint="Titre / n° d'ordre du titre valant autorisation de travail"
                :error-messages="fieldErrors.workers && fieldErrors.workers[index] && fieldErrors.workers[index].work_permit"
              )
            v-col(cols="1" md="1" align-self="center")
              v-btn(text icon color="grey" @click="removeWorker(index)")
                v-icon mdi-delete
          v-row
            v-col(cols="3" offset-md="1" md="2")
              v-btn.mb-3(@click="addWorker") Ajouter
            v-col(
              v-if="workerIsEmptyError && workerIsEmpty"
              cols="9" md="7"
            )
              v-alert(
                type="warning"
                outlined
                text
              ) Vous devez au préalable remplir les champs existants
        v-row(dense)
          v-col(cols="8" offset-md="1" md="5")
            h4 Je soussigné :
            v-text-field(
              label="Prénom"
              v-model="firstName"
              :error-messages="fieldErrors.first_name"
              data-cy="inputFirstName"
            )
            v-text-field(
              label="Nom"
              v-model="lastName"
              :error-messages="fieldErrors.last_name"
              data-cy="inputLastName"
            )
            v-text-field(
              label="Fonction"
              v-model="position"
              :error-messages="fieldErrors.position"
              data-cy="inputPosition"
            )
            v-text-field(
              label="Fait à"
              v-model="location"
              :error-messages="fieldErrors.location"
              data-cy="inputLocation"
            )
        v-row(dense)
          v-col(cols="12" md="11" align="right")
            v-btn.mr-4(:to="{name: 'accounts__carrier', params: { carrierSiret: carrier.siret }}") Annuler
            v-btn(
              color="primary"
              type="submit"
            ) Générer
</template>

<script>
import { mapState } from "vuex";

import api from "@/api";
import CarrierCardHeader from "@/components/CarrierCardHeader";
import { scrollToErrorsMixin, routeLoadCarrierMixin } from "@/mixins";

const EMPTY_WORKER = {
  name: "",
  date: "",
  nationality: "",
  work_permit: "",
};

export default {
  mixins: [scrollToErrorsMixin, routeLoadCarrierMixin],

  props: {
    carrier: {
      type: Object,
      required: true,
    },
  },

  components: {
    CarrierCardHeader,
  },

  data() {
    const user = this.$store.state.user;
    return {
      kind: "NO_WORKERS",
      firstName: user && user.first_name,
      lastName: user && user.last_name,
      position: "",
      location: "",
      workers: [],
      workerIsEmptyError: false,
    };
  },

  created() {
    this.workers.push(this.newWorker());
  },

  computed: {
    isCertificateWithworkers() {
      return this.kind === "WORKERS";
    },

    workerIsEmpty() {
      return this.workers.some(
        (worker) =>
          worker.name === "" ||
          worker.date === "" ||
          worker.nationality === "" ||
          worker.work_permit === ""
      );
    },
    ...mapState(["options"]),
  },

  methods: {
    newWorker() {
      return Object.assign({}, EMPTY_WORKER);
    },

    addWorker() {
      // Only update error on click to display it to user
      this.workerIsEmptyError = this.workerIsEmpty;
      if (this.workerIsEmptyError) {
        return;
      }
      const newWorker = this.newWorker();
      this.workers.push(newWorker);
    },

    removeWorker(index) {
      this.workers.splice(index, 1);
    },

    async sign(e) {
      e.preventDefault();
      const payload = {
        kind: this.kind,
        first_name: this.firstName,
        last_name: this.lastName,
        position: this.position,
        location: this.location,
      };
      if (this.isCertificateWithworkers) {
        payload.workers = this.workers;
      }

      const url = api.getCarrierCertificateUrl(this.carrier.siret);
      const response = await api.post(url, payload);
      if (response.status === 200) {
        // Success
        this.$store.commit("MESSAGE_ADD", response.data);
        this.$router.push({
          name: "accounts__carrier",
          params: { carrierSiret: this.carrier.siret },
          hash: "#carrier-documents",
        });
      } else {
        this.setErrorsAndScrollTo(response.data, this.$refs.mainContent);
      }
    },
  },
};
</script>
